//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE

import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CarouselPropiedad3 from '../Carousel/CarouselPropiedad3';
import { Col, Row } from 'react-bootstrap';
import CarouselPropiedad4 from '../Carousel/CarouselPropiedad4';


const ModalFotos = (props) => {

  return (
    <Modal
      {...props}
      size="lg"
        centered
        className='fotosModel'
    >
      <Modal.Body className='modalBody' >
        <Row className="justify-content-center">
          <Col align="center" style={{'color':'red','width':'50%','height':'50%'}}>
            <div id="imageNone2">
              <CarouselPropiedad3 src={props.src} precio={props.precio} id={props.id} tipo='c' modalShow={props.modalShow} setModalShow={props.setModalShow}/>
            </div>                                                                                                    
            <div id="ipad" className="ms-auto d-block d-md-none">
              <CarouselPropiedad4 src={props.src} precio={props.precio} id={props.id} tipo='c' modalShow={props.modalShow} setModalShow={props.setModalShow}/>
            </div>
            
          </Col>
        </Row>
      </Modal.Body>
   
    </Modal>

  )
}

export default ModalFotos