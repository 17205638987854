import axios from 'axios'
import {urlApi} from '../../../services/apirest'

//constantes
const dataInicial = {
    status : "",
    result : [],
    conectado : false,
    isFetching : false,
    next: null,
    previous: null
}


//Types
const FILTRO_TERRENO_EXITO = 'FILTRO_TERRENO_EXITO'
const FILTRO_TERRENO_ERROR = 'FILTRO_TERRENO_ERROR'
const FILTRO_TERRENO_ESPERANDO = 'FILTRO_TERRENO_ESPERANDO'


const NEXT_FILTRO_TERRENO_EXITO = 'NEXT_FILTRO_TERRENO_EXITO'
const NEXT_FILTRO_TERRENO_ERROR = 'NEXT_FILTRO_TERRENO_ERROR'
const NEXT_FILTRO_TERRENO_ESPERANDO = 'NEXT_FILTRO_TERRENO_ESPERANDO'


const PREVIOUS_FILTRO_TERRENO_EXITO = 'NEXT_FILTRO_TERRENO_EXITO'
const PREVIOUS_FILTRO_TERRENO_ERROR = 'NEXT_FILTRO_TERRENO_ERROR'
const PREVIOUS_FILTRO_TERRENO_ESPERANDO = 'NEXT_FILTRO_TERRENO_ESPERANDO'


//reducer
export default function filtroTerrenoReducer(state = dataInicial, action){
    switch(action.type){

        case FILTRO_TERRENO_ESPERANDO:
            return {
                ...state,
                isFetching: true,
                conectado : false
            }

        case FILTRO_TERRENO_EXITO:
            return {...state, 
                isFetching: false, 
                status: 'ok',
                result: action.payload,
                next: action.payload.result.next,
                previous: action.payload.result.previous,
                conectado : true
            }

        case FILTRO_TERRENO_ERROR:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                result: action.payload.result
            }



            case NEXT_FILTRO_TERRENO_ESPERANDO:
                return {
                    ...state,
                    isFetching: true,
                    conectado : false
                }
    
            case NEXT_FILTRO_TERRENO_EXITO:
                return {...state, 
                    isFetching: false, 
                    status: 'ok',
                    result: action.payload,
                    next: action.payload.result.next,
                    previous: action.payload.result.previous,
                    conectado : true
                }
    
            case NEXT_FILTRO_TERRENO_ERROR:
                return {
                    ...state,
                    status: action.payload.status,
                    isFetching: false,
                    result: action.payload.result
                }

            case PREVIOUS_FILTRO_TERRENO_ESPERANDO:
                return {
                    ...state,
                    isFetching: true,
                    conectado : false
                }
    
            case PREVIOUS_FILTRO_TERRENO_EXITO:
                return {...state, 
                    isFetching: false, 
                    status: 'ok',
                    result: action.payload,
                    next: action.payload.result.next,
                    previous: action.payload.result.previous,
                    conectado : true
                }
    
            case PREVIOUS_FILTRO_TERRENO_ERROR:
                return {
                    ...state,
                    status: action.payload.status,
                    isFetching: false,
                    result: action.payload.result
                }
        
        default:
            return state
    }
}

//acciones
export const filtroTerreno = (metrosDesde,metrosHasta,precioDesde,precioHasta,ubicacion,orderBy) => async (dispatch) => {

    const url = urlApi + 'terreno'
    dispatch({
        type: FILTRO_TERRENO_ESPERANDO,
    })

    try {
        const res = await axios.get(url+'?page=1&metrosDesde='+metrosDesde+'&metrosHasta='+metrosHasta+'&precioDesde='+precioDesde+'&precioHasta='+precioHasta+'&ubicacion='+ubicacion+'&orderBy='+orderBy)
        dispatch({
            type: FILTRO_TERRENO_EXITO,
            payload: res.data
        })

    }catch (error) {

        dispatch({
            type: FILTRO_TERRENO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
 
} 

export const siguientePaginaFiltroTerreno = (metrosDesde,metrosHasta,precioDesde,precioHasta,ubicacion,orderBy) => async (dispatch,getState) => {

    const next = getState().filtroTerreno.next


    

    const url = urlApi + 'terreno'

    dispatch({
        type: NEXT_FILTRO_TERRENO_ESPERANDO,
    })

    try {

        const res = await axios.get(url+'?page='+next+'&metrosDesde='+metrosDesde+'&metrosHasta='+metrosHasta+'&precioDesde='+precioDesde+'&precioHasta='+precioHasta+'&ubicacion='+ubicacion+'&orderBy='+orderBy)

        dispatch({
            type: NEXT_FILTRO_TERRENO_EXITO,
            payload: res.data
        })


    } catch (error) {
        dispatch({
            type: NEXT_FILTRO_TERRENO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}


export const anteriorPaginaFiltroTerreno = (metrosDesde,metrosHasta,precioDesde,precioHasta,ubicacion,orderBy) => async (dispatch,getState) => {

    const previous = getState().filtroTerreno.previous

    const url = urlApi + 'terreno'

    dispatch({
        type: PREVIOUS_FILTRO_TERRENO_ESPERANDO,
    })

    try {

        const res = await axios.get(url+'?page='+previous+'&metrosDesde='+metrosDesde+'&metrosHasta='+metrosHasta+'&precioDesde='+precioDesde+'&precioHasta='+precioHasta+'&ubicacion='+ubicacion+'&orderBy='+orderBy)

        dispatch({
            type: PREVIOUS_FILTRO_TERRENO_EXITO,
            payload: res.data
        })


    } catch (error) {
        dispatch({
            type: PREVIOUS_FILTRO_TERRENO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }

}