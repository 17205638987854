//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Button,Col, Row } from 'react-bootstrap'
import '../../css/propiedades.css'
import {useDispatch, useSelector} from 'react-redux'
import {filtroCampo, siguientePaginaFiltroCampo, anteriorPaginaFiltroCampo} from '../../redux/redux-front/campos/FiltroCampoDucks'
import Campo from './Campo'
import NotFound from '../NotFound'


const Campos = ({tipo,datos}) => {

const dispatch = useDispatch()

const [propiedadTipo, setPropiedadTipo] = React.useState('')

React.useEffect(() => {
    setPropiedadTipo(tipo)
  },[tipo]) 


        const campos = useSelector(store => store.filtroCampo)
        const next = useSelector(store => store.filtroCampo.next)
        const previous = useSelector(store => store.filtroCampo.previous) 

        let precioDesdeCA
        let precioHastaCA
        let hectariasDesdeCA
        let hectariasHastaCA


React.useEffect(() => {

    if(propiedadTipo === 'CA'){

        precioDesdeCA = 'SD'
        precioHastaCA = 'SD'
        hectariasDesdeCA = 'SD'
        hectariasHastaCA = 'SD'

        //Precio
        if(datos.precio === "0-100"){
          precioDesdeCA = 0
          precioHastaCA = 100000
        }
        if(datos.precio === "100-300"){
          precioDesdeCA = 100000
          precioHastaCA = 300000
        }
        if(datos.precio === "300-500"){
          precioDesdeCA = 300000
          precioHastaCA = 500000
        }
        if(datos.precio === "+500"){
          precioDesdeCA = 500000
          precioHastaCA = 100000000
        }
        if(datos.precio === "SD"){
          precioDesdeCA = 'SD'
          precioHastaCA = 'SD'
        }     

        //Hectarias
        if(datos.hectarias === "0-5"){
          hectariasDesdeCA = 0
          hectariasHastaCA = 5
        }
        if(datos.hectarias === "5-30"){
          hectariasDesdeCA = 5
          hectariasHastaCA = 30
        }
        if(datos.hectarias === "30-100"){
          hectariasDesdeCA = 30
          hectariasHastaCA = 100
        }
        if(datos.hectarias === "+100"){
          hectariasDesdeCA = 100
          hectariasHastaCA = 100000000
        }
        if(datos.hectarias === "SD"){
          hectariasDesdeCA = 'SD'
          hectariasHastaCA = 'SD'
        }



        const fetchData = () => {
            dispatch(filtroCampo(hectariasDesdeCA,hectariasHastaCA,precioDesdeCA,precioHastaCA,datos.ubicacion,datos.orderBy))
            
            
    }
    fetchData()
}
    
},[dispatch,propiedadTipo,datos])  


const scrollUp = () =>{
  let currentScroll = document.documentElement.scrollTop
  if(currentScroll > 0){
    window.scrollTo(0,0)
  }
}


const siguiente = () => {

  if(propiedadTipo === 'CA'){

    precioDesdeCA = 'SD'
    precioHastaCA = 'SD'
    hectariasDesdeCA = 'SD'
    hectariasHastaCA = 'SD'

    //Precio
    if(datos.precio === "0-100"){
      precioDesdeCA = 0
      precioHastaCA = 100000
    }
    if(datos.precio === "100-300"){
      precioDesdeCA = 100000
      precioHastaCA = 300000
    }
    if(datos.precio === "300-500"){
      precioDesdeCA = 300000
      precioHastaCA = 500000
    }
    if(datos.precio === "+500"){
      precioDesdeCA = 500000
      precioHastaCA = 100000000
    }
    if(datos.precio === "SD"){
      precioDesdeCA = 'SD'
      precioHastaCA = 'SD'
    }     

    //Hectarias
    if(datos.hectarias === "0-5"){
      hectariasDesdeCA = 0
      hectariasHastaCA = 5
    }
    if(datos.hectarias === "5-30"){
      hectariasDesdeCA = 5
      hectariasHastaCA = 30
    }
    if(datos.hectarias === "30-100"){
      hectariasDesdeCA = 30
      hectariasHastaCA = 100
    }
    if(datos.hectarias === "+100"){
      hectariasDesdeCA = 100
      hectariasHastaCA = 100000000
    }
    if(datos.hectarias === "SD"){
      hectariasDesdeCA = 'SD'
      hectariasHastaCA = 'SD'
    }

  dispatch(siguientePaginaFiltroCampo(hectariasDesdeCA,hectariasHastaCA,precioDesdeCA,precioHastaCA,datos.ubicacion,datos.orderBy))
  scrollUp()
  }
}


const anterior = () => {

  if(propiedadTipo === 'CA'){

    precioDesdeCA = 'SD'
    precioHastaCA = 'SD'
    hectariasDesdeCA = 'SD'
    hectariasHastaCA = 'SD'

    //Precio
    if(datos.precio === "0-100"){
      precioDesdeCA = 0
      precioHastaCA = 100000
    }
    if(datos.precio === "100-300"){
      precioDesdeCA = 100000
      precioHastaCA = 300000
    }
    if(datos.precio === "300-500"){
      precioDesdeCA = 300000
      precioHastaCA = 500000
    }
    if(datos.precio === "+500"){
      precioDesdeCA = 500000
      precioHastaCA = 100000000
    }
    if(datos.precio === "SD"){
      precioDesdeCA = 'SD'
      precioHastaCA = 'SD'
    }     

    //Hectarias
    if(datos.hectarias === "0-5"){
      hectariasDesdeCA = 0
      hectariasHastaCA = 5
    }
    if(datos.hectarias === "5-30"){
      hectariasDesdeCA = 5
      hectariasHastaCA = 30
    }
    if(datos.hectarias === "30-100"){
      hectariasDesdeCA = 30
      hectariasHastaCA = 100
    }
    if(datos.hectarias === "+100"){
      hectariasDesdeCA = 100
      hectariasHastaCA = 100000000
    }
    if(datos.hectarias === "SD"){
      hectariasDesdeCA = 'SD'
      hectariasHastaCA = 'SD'
    }

    dispatch(anteriorPaginaFiltroCampo(hectariasDesdeCA,hectariasHastaCA,precioDesdeCA,precioHastaCA,datos.ubicacion,datos.orderBy))
    scrollUp()
  }
}


    let [error_msg, setError_msg] = React.useState('') 
    let [cargando_msg, setCargando_msg] = React.useState('')


    React.useEffect(() => {

        if(campos.isFetching === true){
            setCargando_msg('Cargando')
        }

        if(campos.isFetching === false && campos.status === "error"){
            setCargando_msg('')
            setError_msg(campos.results.error_msg)
            
        }

        

    },[campos])


    let msgError = []
    if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    let msgCargando = []
    if(cargando_msg !== ''){
        msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
    } 



  return (
    <>
        {(campos.isFetching === true && campos.status === '') ? (null) :( 
    <>

    <div className="container d-flex justify-content-center h-100">
        <Col >

        <Row className="g-0" xs={12} sm={12} md={12} lg={10} xl={12} xxl={12}>
        {
         
            (campos.result.length !== 0)?(
            (campos.result.result.datos !== null) ? (
                campos.status === 'ok' &&
                campos.result.result.datos.map((item)=>(
                <Col xs={12} sm={9} md={10} lg={5} xl={4} xxl={4} key={item.campoId} className="px-sm-2 px-lg-2 px-md-2 colPropiedad">
                    <Campo id={item.campoId}  image={item.image} hectarias={item.hectarias} tipoCampo={item.tipoCampo} precio={item.precio} ubicacion={item.ubicacion} ubicacionAux={item.ubicacionAux}/>
                </Col>  
            ))

            ):(
              <>
                <NotFound/>
                </>
            )
            ):(null)
  
        }
        </Row>
      
        <>

         <Row className="justify-content-center p-0 m-0">
            <Col md={3} lg={3} className="p-3"></Col>
            <Col xs ={12} md={6} lg={6} className="p-3">
                <Row className="justify-content-center ">


                
                {
                (campos.previous !== null)?(
                (previous!==0) ? (
                    <Col md={2} xs={4} lg={2}>
                        <Button style={{ 'backgroundColor': '#443E38', 'border': 'white'}}  onClick={( ) =>  anterior() } >
                            Anterior
                        </Button>
                    </Col>
                ):(null)
              ):(null)}
               

                
                {
                (campos.next !== null)?(
                (next !== null) ? (
                    (next) ? (
                    <Col md={2} xs={2} lg={2} >
                        <Button style={{ 'backgroundColor': '#443E38', 'border': 'white'}} onClick={( ) => siguiente() } >
                            Siguiente
                        </Button>
                    </Col>
                ):null
                ):null
                ):(null)}

                    
                </Row>
            </Col>
            <Col md={3} className="p-3"></Col> 
        </Row> 
         </>

        </Col>
        
    </div>
    
    </>
         )} 
    </>
  )
}

export default Campos