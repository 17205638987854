//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Navbar,Nav,Container } from 'react-bootstrap';
import logo from '../../img/logo_horizontal.jpeg'
import Icon from '@mdi/react'
import { mdiCellphoneBasic} from '@mdi/js';
import '../../css/navBar.css'

const NavBar = ({propiedadSelect,setPropiedadSelect}) => {

    React.useEffect(() => {
        if(propiedadSelect !== localStorage.getItem('type')){
            if(localStorage.getItem('type')){
                document.getElementById(`${localStorage.getItem('type')}`).classList.remove("active")
            }
        }
        
    }, [propiedadSelect,localStorage.getItem('type')])
    

  return (
  
  <Navbar collapseOnSelect expand="lg" variant="dark" className='navBar' style={{'padding-right':'3%','padding-left':'3%'}}>
    <Container fluid>

        <Navbar.Brand href="/">
            <img
                    src={logo}
                    className="d-inline-block align-top"
                    height="60"
            />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav onSelect={(selectedKey) => setPropiedadSelect(selectedKey)}>
                <Nav.Link className='navBarLink'  eventKey="C" id="C">Casas</Nav.Link>
                <Nav.Link className='navBarLink' eventKey="A" id="A">Apartamentos</Nav.Link>
                <Nav.Link className='navBarLink' eventKey="T" id="T">Terrenos</Nav.Link>
                <Nav.Link className='navBarLink' eventKey="CA" id="CA">Campos</Nav.Link>
                <Nav.Link className='navBarLink' eventKey="CO" id="CO">Contacto</Nav.Link>
            </Nav>
            <Navbar.Brand href="tel:099933330" className='celColor'>
                <Icon path={mdiCellphoneBasic } size={1.3}/>099 933 330
            </Navbar.Brand>

        </Navbar.Collapse>

    </Container>
 </Navbar>

  )
}

export default NavBar