//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CarouselPropiedad from '../Carousel/CarouselPropiedad';
import Contacto from '../Contactos/Contacto';
import Footer from '../Footer';
import Icon from '@mdi/react'
import { mdiRulerSquare,mdiAlphaIBoxOutline,mdiAlphaPBoxOutline,mdiAlphaFBoxOutline } from '@mdi/js';
import MapPropiedad from '../MapPropiedad';
import '../../css/contacto.css'
import {filtroUnTerreno} from '../../redux/redux-front/terrenos/UnTerrenoDucks'

import {useDispatch, useSelector} from 'react-redux'
import NavPropiedad from '../Nav/NavPropiedad';
import CarouselPropiedad2 from '../Carousel/CarouselPropiedad2';
import ModalFotos from './ModalFotos';

const TerrenoId = () => {

    const {id} = useParams();

    const dispatch = useDispatch()

    const unTerreno = useSelector(store => store.filtroUnTerreno)
    
    React.useEffect(() => {
        const fetchData = () => {
          if(id !== ''){
            dispatch(filtroUnTerreno(id))
          }
          
        }
      fetchData()
  
    },[dispatch]) 

    let [error_msg, setError_msg] = React.useState('') 
    let [cargando_msg, setCargando_msg] = React.useState('')


    React.useEffect(() => {
         
        if(unTerreno.isFetching === true){
            setCargando_msg('Cargando')
        }

        if(unTerreno.result.result !== undefined && unTerreno.isFetching === false && unTerreno.status === "error"){
            setCargando_msg('')
            setError_msg(unTerreno.results.error_msg)
            
        }

    },[unTerreno])


    let msgError = []
    if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    let msgCargando = []
    if(cargando_msg !== ''){
        msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
    } 

    const formatNumber = (num) => {
      return new Intl.NumberFormat().format(num)
    }

    const [modalShow, setModalShow] = React.useState(false);

  return (
    <Row xs={12} md={12} lg={12} xl={12} className='gx-0'>

        <NavPropiedad/>

    {(unTerreno.result !== null)?(
      <>
        <Row xs={12} sm={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
          <Col xs={12} sm={12} md={8} lg={8} xl={8} >
            <div id="imageNone"> 
              <CarouselPropiedad src={unTerreno.result.image} precio={unTerreno.result.precio} id={id} tipo='t' modalShow={modalShow} setModalShow={setModalShow}/>
            </div>
            <div id="ipad" className="ms-auto d-block d-md-none">
              <CarouselPropiedad2 src={unTerreno.result.image} precio={unTerreno.result.precio} id={id} tipo='t' modalShow={modalShow} setModalShow={setModalShow}/>
            </div>
          </Col>

          <Col xs={12} md={5} lg={5} xl={4} className="paddingContacto" id="contacto" /* style={{'padding-bottom': '0px !important','padding-top': '3% !important' }}*/ /* p-5 */ >

              <Contacto className='rowContacto' tipo='t'/>

          </Col>
        </Row>


      {(unTerreno.result.boolDescripcion === '1')?(
      <>
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
            <Col xs={12} md={8} lg={8} xl={8}>
              <Row className="justify-content-md-center">
                <Col xs lg="2" align="center">
                    <h2><font color="#23395B">Descripción</font></h2>
                </Col>
              </Row>
            </Col>
      </Row>
      
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingId">
            <Col xs={12} md={8} lg={8} xl={8} className="paddingMobile p-md-4">
              <Row className="justify-content-md-center">
                <Col xs={12} md={8} lg={8} xl={8} align="left" className='widht'>
                <p style={{ whiteSpace: "pre-line" }}><font color="#23395B">{unTerreno.result.descripcion}</font></p>                
                </Col>
              </Row>
            </Col>
      </Row>
      </>
      ):(null)}
        
        
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter widthRow paddingId">
            <Col xs={12} md={8} lg={8} xl={8}>
              <Row className="justify-content-md-center">
                <Col xs lg="2" align="center">
                    <h2><font color="#23395B">Características</font></h2>
                </Col>
              </Row>
            </Col>
        </Row>

        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap paddingId">{/* widthRow */}
            <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable " >

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRulerSquare} size={1}  className="align-middle" title="metros"/> Metros²</td>
                            <td className='text-left'>{unTerreno.result.metros}</td>
                        </tr>
                      }
                    </tbody>


                </Table>
            </Col>

            <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline} title="imp. inmobiliario" size={1}  className="align-middle"/> Imp. inmobiliario</td>

                            {(unTerreno.result.impuestoInmobiliario === '0')?(<td className='text-left'>${formatNumber(unTerreno.result.impuestoInmobiliario)}</td>):
                            ((unTerreno.result.impuestoInmobiliario === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unTerreno.result.impuestoInmobiliario)}</td>
                            ))
                            }
                        </tr>
                      }
                    </tbody>
                </Table>
            </Col>

            <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaPBoxOutline} size={1}  className="align-middle" title="acepta permutas"/> Acepta Permutas</td>
                            <td className='text-left'>{(unTerreno.result.aceptaPermutas === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>
            </Col>

            <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaFBoxOutline} size={1}  className="align-middle" title="acepta financiación"/> Acepta Financiación</td>
                            <td className='text-left'>{(unTerreno.result.aceptaFinanciacion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>
            </Col>

        </Row>
        </>
      ):(null)}

      <MapPropiedad longitud={unTerreno.result.longitud} latitud={unTerreno.result.latitud}/>

      <ModalFotos
        show={modalShow}
        onHide={() => setModalShow(false)}
        className='modal fondoModal'
        src={unTerreno.result.image} precio={unTerreno.result.precio} id={id} tipo='c'
        modalShow={modalShow} setModalShow={setModalShow}
  />
      <Footer/>

  </Row>


  )
}

export default TerrenoId