import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import filtroCampoReducer from './redux-front/campos/FiltroCampoDucks';
import filtroTerrenoReducer from './redux-front/terrenos/FiltroTerrenoDucks';
import filtroPorPropiedadTotalReducer from './redux-front/propiedades/FiltroTotalDucks';
import filtroUnaPropiedadReducer from './redux-front/propiedades/UnaPropiedadDucks';
import filtroUnTerrenoReducer from './redux-front/terrenos/UnTerrenoDucks';
import filtroUnCampoReducer from './redux-front/campos/UnCampoDucks';

import filtroUnTerrenoOcultoReducer from './redux-front/terrenos/UnTerrenoOcultoDucks';
import filtroUnaPropiedadOcultoReducer from './redux-front/propiedades/UnaPropiedadOcultoDucks';
import filtroUnCampoOcultoReducer from './redux-front/campos/UnCampoOculto';
import verifyPassReducer from './redux-front/PassDucks';


const rootReducer = combineReducers({

    //Front
    filtroCampo: filtroCampoReducer,
    filtroTerreno: filtroTerrenoReducer,
    filtroPorPropiedadTotal: filtroPorPropiedadTotalReducer, 
    filtroUnaPropiedad: filtroUnaPropiedadReducer,
    filtroUnTerreno: filtroUnTerrenoReducer,
    filtroUnCampo: filtroUnCampoReducer,

    //Ocult
    filtroUnTerrenoOculto: filtroUnTerrenoOcultoReducer,
    filtroUnaPropiedadOculto: filtroUnaPropiedadOcultoReducer,
    filtroUnCampoOculto: filtroUnCampoOcultoReducer,

    verifyPass: verifyPassReducer


})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ))
    return store;
} 