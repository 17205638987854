//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Button,Col,Row} from 'react-bootstrap'
import Propiedad from './Propiedad'
import '../../css/propiedades.css'
import {useDispatch, useSelector} from 'react-redux'
import {filtroPorPropiedadTotal, siguientePaginaFiltroPorPropiedadTotal, anteriorPaginaFiltroPorPropiedadTotal} from '../../redux/redux-front/propiedades/FiltroTotalDucks'


import NotFound from '../NotFound'

const Propiedades = ({tipo,datos}) => {

    const dispatch = useDispatch()

    const [propiedadTipo, setPropiedadTipo] = React.useState('') 


    React.useEffect(() => {
      setPropiedadTipo(tipo)
    },[tipo]) 

    const propiedades = useSelector(store => store.filtroPorPropiedadTotal)
    const next = useSelector(store => store.filtroPorPropiedadTotal.next)
    const previous = useSelector(store => store.filtroPorPropiedadTotal.previous) 


     React.useEffect(() => {

        if(propiedadTipo === 'C' || propiedadTipo === 'A'){
        
            let precioDesdeP = 'SD'
            let precioHastaP = 'SD'

            if(datos.garage === false){
                datos.garage = 'SD'
            }

            if(datos.precio === "0-100"){
                precioDesdeP = 0
                precioHastaP = 100000
              }
            if(datos.precio === "100-300"){
                precioDesdeP = 100000
                precioHastaP = 300000
            }
            if(datos.precio === "300-500"){
                precioDesdeP = 300000
                precioHastaP = 500000
            }
            if(datos.precio === "+500"){
                precioDesdeP = 500000
                precioHastaP = 100000000
            }
            if(datos.precio === "SD"){
                precioDesdeP = 'SD'
                precioHastaP = 'SD'
            }

            const fetchData = () => {
                dispatch(filtroPorPropiedadTotal(datos.tipo,datos.ubicacion,datos.dormitorio,datos.bano,precioDesdeP,precioHastaP,datos.garage,datos.orderBy))
            }
            fetchData()
        }
        
    },[dispatch,propiedadTipo,datos])  

    const scrollUp = () =>{
        let currentScroll = document.documentElement.scrollTop
        if(currentScroll > 0){
          window.scrollTo(0,0)
        }
      }

    const anterior = () => {
        if(propiedadTipo === 'C' || propiedadTipo === 'A'){
        
            let precioDesdeP = 'SD'
            let precioHastaP = 'SD'

            if(datos.garage === false){
                datos.garage = 'SD'
            }

            if(datos.precio === "0-100"){
                precioDesdeP = 0
                precioHastaP = 100000
              }
            if(datos.precio === "100-300"){
                precioDesdeP = 100000
                precioHastaP = 300000
            }
            if(datos.precio === "300-500"){
                precioDesdeP = 300000
                precioHastaP = 500000
            }
            if(datos.precio === "+500"){
                precioDesdeP = 500000
                precioHastaP = 100000000
            }
            if(datos.precio === "SD"){
                precioDesdeP = 'SD'
                precioHastaP = 'SD'
            }
        dispatch(anteriorPaginaFiltroPorPropiedadTotal(datos.tipo,datos.ubicacion,datos.dormitorio,datos.bano,precioDesdeP,precioHastaP,datos.garage,datos.orderBy))
        scrollUp()
        }
    }

    const siguiente = () => {

        if(propiedadTipo === 'C' || propiedadTipo === 'A'){
        
            let precioDesdeP = 'SD'
            let precioHastaP = 'SD'

            if(datos.garage === false){
                datos.garage = 'SD'
            }

            if(datos.precio === "0-100"){
                precioDesdeP = 0
                precioHastaP = 100000
              }
            if(datos.precio === "100-300"){
                precioDesdeP = 100000
                precioHastaP = 300000
            }
            if(datos.precio === "300-500"){
                precioDesdeP = 300000
                precioHastaP = 500000
            }
            if(datos.precio === "+500"){
                precioDesdeP = 500000
                precioHastaP = 100000000
            }
            if(datos.precio === "SD"){
                precioDesdeP = 'SD'
                precioHastaP = 'SD'
            }
        dispatch(siguientePaginaFiltroPorPropiedadTotal(datos.tipo,datos.ubicacion,datos.dormitorio,datos.bano,precioDesdeP,precioHastaP,datos.garage,datos.orderBy))
        scrollUp()
        }
    }



    let [error_msg, setError_msg] = React.useState('') 
    let [cargando_msg, setCargando_msg] = React.useState('')


    React.useEffect(() => {
         
        
        if(propiedades.isFetching === true){
            setCargando_msg('Cargando')
        }

        if(propiedades.isFetching === false && propiedades.status === "error"){
            setCargando_msg('')
            setError_msg(propiedades.results.error_msg)
            
        }

    },[propiedades])


    let msgError = []
    if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    let msgCargando = []
    if(cargando_msg !== ''){
        msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
    } 


  return (
    <>
        {(propiedades.isFetching === true && propiedades.status === '') ? (null) :( 
    <>

    <div className="container d-flex justify-content-center h-100 "> 
        <Col >

        <Row className="g-0" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            {
            
            (propiedades.result.length !== 0)?(
            (propiedades.result.result.datos !==null) ? (
            propiedades.status === 'ok' &&
            propiedades.result.result.datos.map((item)=>(
                <Col xs={12} sm={9} md={10} lg={5} xl={4} xxl={4} key={item.propiedadId} className="px-sm-2 px-lg-2 px-md-2 colPropiedad">
                    <Propiedad id={item.propiedadId}  image={item.image} dormitorios={item.dormitorio} banos={item.banos} precio={item.precio} ubicacion={item.ubicacion} ubicacionAux={item.ubicacionAux} metrosEdificados={item.metrosEdificados}/>
                </Col>  
            ))

            ):(
                <>
                <NotFound/>
                </>
                
            )
            ):(null)
        }
        </Row>

        <>

         <Row className="justify-content-center p-0 m-0">
            <Col md={3} lg={3} className="p-3"></Col>
            <Col xs ={12} md={6} lg={6} className="p-3">
                <Row className="justify-content-center ">


                
                {
                (propiedades.previous !== null) ? (
                (previous!==0) ? (
                    <Col md={2} xs={4} lg={2}>
                        <Button style={{ 'backgroundColor': '#443E38', 'border': 'white'}}  onClick={( ) => anterior() } >
                            Anterior
                        </Button>
                    </Col>
                ):(null)
                ):(null)}
               

                
                {
                (propiedades.next !== null)?(
                (next !== null) ? (
                    (next) ? (
                    <Col md={2} xs={2} lg={2} >
                        <Button style={{ 'backgroundColor': '#443E38', 'border': 'white'}} onClick={( ) => siguiente() } >
                            Siguiente
                        </Button>
                    </Col>
                ):null
                ):null
                ):(null)
                }

                    
                </Row>
            </Col>
            <Col md={3} className="p-3"></Col> 
        </Row> 
        </>      
        </Col>
        
    </div>
    
    </>
         )} 
    </>
  )
}

export default Propiedades