//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Button, Card, Col, Row,Stack } from 'react-bootstrap'
import '../../css/card.css'
import SectorImages from '../SectorImages'
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiRulerSquare,mdiMapMarker   } from '@mdi/js';
import '../../css/propiedades.css'

const Propiedad = (props) => {


  return (
    <Card xs={12}>
     <Card.Body xs={12}>

     <SectorImages images={props.image} precio={props.precio}/>
       
       <Card.Title className='pt-2'>
      
        <Stack direction="horizontal" gap={3}>

        {(props.ubicacion === 'Otros')?(
         <div><Icon path={mdiMapMarker} title="ubicación" size={1} className="align-middle colorIconos p-0.8"/>{props.ubicacionAux}</div>
        ):(
         <div><Icon path={mdiMapMarker} title="ubicación" size={1} className="align-middle colorIconos p-0.8"/>{props.ubicacion}</div>
        )}

        </Stack>
       </Card.Title>

       <hr></hr>
       <Card.Text>
          <Row className="justify-content-center pt-2 ">

            <Col md={12} xs={11} align="center">
               <Row>
             <Col align="center">
                <Icon path={mdiBedKing} title="dormitorios" size={1} className="align-middle colorIconos p-0.8 m-0.5"/>
                <b>{(props.dormitorios.toString().endsWith('0'))?(Math.trunc(props.dormitorios)):(props.dormitorios)}</b>
             </Col>
             <Col align="center" style={{'padding-left':'0px'}}>
                <Icon path={mdiShower} title="baños" size={1} className="align-middle colorIconos p-0.8 m-0.5"/> 
                <b>{props.banos}</b>
             </Col>
             <Col align="center" style={{'padding-left':'0px'}}>
                <Icon path={mdiRulerSquare} title="metros cuadrados" size={1} className="align-middle colorIconos p-0.8 m-0.5"/> 
                <b>{props.metrosEdificados}m²</b>
             </Col>
             </Row>
             </Col>
          </Row>

       </Card.Text>

        <Row>
            <Col className="d-grid gap-2">
                <Button style={{ 'backgroundColor': '#8c3839', 'border': 'white'}} href={`/propiedad/${props.id}`} target="_blank">Ver más</Button>
            </Col>
        </Row>

     </Card.Body>
    </Card>
  )
}

export default Propiedad