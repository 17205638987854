//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE

import React from 'react'
import { Modal } from 'react-bootstrap';

const ModalConfirm = (props) => {

 let title
 let body
 if(props.response === 'OK'){
     title = 'Tu consulta fue enviada'
     body = 'Nos estaremos comunicando con usted.'
 }
 if(props.response === 'ERROR'){
     title = 'Opsss - hubo un problema con el envio de tu consulta!'
     body = 'Tu consulta no pudo ser enviada. Por favor contáctese al: 099 933 330 o info@will.com.uy'
 }



  return (
    <div>
             <Modal
                 {...props}
                size="mg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

    > 
                  <Modal.Header closeButton>  
                    <Modal.Title id="contained-modal-title-vcenter"> 
                    
                     {title} 
                     </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>{body}</p> 
                </Modal.Body>
                </Modal> 
        </div>
  )
}

export default ModalConfirm