//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CarouselPropiedad from '../Carousel/CarouselPropiedad';
import Contacto from '../Contactos/Contacto';
import Footer from '../Footer';
import Icon from '@mdi/react'
import { mdiBedKing,mdiShower,mdiRulerSquare,mdiOfficeBuilding ,mdiCompassOutline,mdiBorderInside,mdiCalendarRange,mdiElevatorPassengerOutline,mdiHomeFloor0,mdiHomeFloor1,mdiHomeFloor2,mdiHomeFloor3,mdiAlphaGBoxOutline,mdiSortCalendarAscending,mdiAlphaIBoxOutline,mdiStove,mdiBalcony,mdiWashingMachine,mdiShovel,mdiGarageOpenVariant,mdiGarageVariantLock,mdiAccountLockOutline,mdiGrillOutline,mdiRadiator,mdiSunThermometer ,mdiGasBurner,mdiAlphaPBoxOutline,mdiAlphaFBoxOutline,mdiSunglasses  ,mdiPool,mdiBilliards,mdiWeightLifter,mdiSunThermometerOutline ,mdiWaves,mdiTumbleDryer,mdiSoccerField,mdiVacuumOutline,mdiTheater,mdiWifi,mdiBoomGate ,mdiUmbrellaBeachOutline } from '@mdi/js';
import MapPropiedad from '../MapPropiedad';
import '../../css/contacto.css'
import {filtroUnaPropiedad} from '../../redux/redux-front/propiedades/UnaPropiedadDucks'


import {useDispatch, useSelector} from 'react-redux'
import NavPropiedad from '../Nav/NavPropiedad';
import CarouselPropiedad2 from '../Carousel/CarouselPropiedad2';
import ModalFotos from './ModalFotos';

const PropiedadId = () => {

    const {id} = useParams();
  

    const dispatch = useDispatch()

    const unaPropiedades = useSelector(store => store.filtroUnaPropiedad)

    React.useEffect(() => {
      const fetchData = () => {
        if(id !== ''){
          dispatch(filtroUnaPropiedad(id))
        }
        
      }
    fetchData()

    },[dispatch]) 


    let [error_msg, setError_msg] = React.useState('') 
    let [cargando_msg, setCargando_msg] = React.useState('')


    React.useEffect(() => {
         
        
        if(unaPropiedades.isFetching === true){
            setCargando_msg('Cargando')
        }

        if(unaPropiedades.result.result !== undefined && unaPropiedades.isFetching === false && unaPropiedades.status === "error"){
            setCargando_msg('')
            setError_msg(unaPropiedades.results.error_msg)
            
        }

    },[unaPropiedades])


    let msgError = []
    if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    let msgCargando = []
    if(cargando_msg !== ''){
        msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
    } 
    
    const formatNumber = (num) => {
      return new Intl.NumberFormat().format(num)
    }
    
    const [modalShow, setModalShow] = React.useState(false);

  return (

    

    
    <Row className='gx-0'>

        <NavPropiedad/>


     {(unaPropiedades.result !== null)?(
      <>
        <Row xs={12} sm={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div id="imageNone">
              <CarouselPropiedad src={unaPropiedades.result.image} precio={unaPropiedades.result.precio} id={id} tipo='p' modalShow={modalShow} setModalShow={setModalShow}/>
            </div>
            <div id="ipad" className="ms-auto d-block d-md-none">
              <CarouselPropiedad2 src={unaPropiedades.result.image} precio={unaPropiedades.result.precio} id={id} tipo='p' modalShow={modalShow} setModalShow={setModalShow}/>
            </div>
          </Col>

          <Col xs={12} md={5} lg={5} xl={4} className="paddingContacto" id="contacto" >

              <Contacto className='rowContacto' tipo='p' />

          </Col>
        </Row>


        {(unaPropiedades.result.boolDescripcion === '1')?(
        <>
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
            <Col xs={12} md={8} lg={8} xl={8}>
              <Row className="justify-content-md-center">
                <Col xs lg="2" align="center">
                    <h2><font color="#23395B">Descripción</font></h2>
                </Col>
              </Row>
            </Col>
      </Row>
      
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingId">
            <Col xs={12} md={8} lg={8} xl={8} className="paddingMobile p-md-4">
              <Row className="justify-content-md-center">
              <Col xs={12} md={8} lg={8} xl={8} align="left" className='widht'>
                    <p style={{ whiteSpace: "pre-line" }}><font color="#23395B" >{unaPropiedades.result.descripcion}</font></p>
                </Col>
              </Row>
            </Col>
      </Row>
      </>
      ):(null)}
        
     
        
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter widthRow paddingId">
            <Col xs={12} md={8} lg={8} xl={8}>
              <Row className="justify-content-md-center">
                <Col xs lg="2" align="center">
                    <h2><font color="#23395B">Características</font></h2>
                </Col>
              </Row>
            </Col>
        </Row>

        {(unaPropiedades.result.propiedad === 'A')?(
        /* APARTAMENTO */
        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap paddingId">{/* widthRow */}
            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable">

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBedKing} size={1}  className="align-middle" title="dormitorios"/> Dormitorios</td>
                            <td className='text-left'>{(unaPropiedades.result.dormitorio)?((unaPropiedades.result.dormitorio.toString().endsWith('0'))?(Math.trunc(unaPropiedades.result.dormitorio)):(unaPropiedades.result.dormitorio)):(null)}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiShower} title="baños" size={1}  className="align-middle"/> Baños</td>
                            <td className='text-left'>{unaPropiedades.result.banos}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRulerSquare} title="metros" size={1}  className="align-middle"/> Metros²</td>
                            <td className='text-left'>{unaPropiedades.result.metrosEdificados}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiCompassOutline } title="orientación" size={1}  className="align-middle"/> Orientación</td>
                            <td className='text-left'>{unaPropiedades.result.orientacion}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBorderInside } title="disposición" size={1}  className="align-middle"/> Disposición</td>

                            {(unaPropiedades.result.disposicion === 'FRENTE')?(<td className='text-left'>Frente</td>):
                            ((unaPropiedades.result.disposicion === 'CONTRAFRENTE')?(<td className='text-left'>Contrafrente</td>):
                            ((unaPropiedades.result.disposicion === 'LATERAL')?(<td className='text-left'>Lateral</td>):
                            (unaPropiedades.result.disposicion === 'INTERIOR'))?(<td className='text-left'>Interior</td>):(
                              <td className='text-left'>{unaPropiedades.result.disposicion}</td>
                            ))
                          }
                            
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiCalendarRange } title="año constr." size={1}  className="align-middle"/> Año Constr.</td>
                            {(unaPropiedades.result.anoConstruccion === '1')?(<td className='text-left'>----</td>):
                            ((unaPropiedades.result.anoConstruccion === '2')?(<td className='text-left'>Reciclado</td>):
                            (<td className='text-left'>{unaPropiedades.result.anoConstruccion}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiElevatorPassengerOutline } title="asensor" size={1}  className="align-middle"/> Asensor</td>
                            <td className='text-left'>{(unaPropiedades.result.asensor === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      
                      
                      
                    </tbody>
                </Table>
            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                    {
                        <tr>
                            
                              {(unaPropiedades.result.plantas === '1')?(
                                <td className='withTd'><Icon path={mdiHomeFloor1} title="plantas" size={1}  className="align-middle" /> Plantas</td>
                                ):
                              ((unaPropiedades.result.plantas === '2')?(<td className='withTd'><Icon path={mdiHomeFloor2} title="plantas" size={1}  className="align-middle"/> Plantas</td>)
                                
                              :((unaPropiedades.result.plantas === '3')?(<td className='withTd'><Icon path={mdiHomeFloor3} title="plantas" size={1}  className="align-middle" /> Plantas</td>):
                              (<td className='withTd'><Icon path={mdiHomeFloor0 } title="plantas" size={1}  className="align-middle"/> Plantas</td>))
                              )}
                              
                            
                            
                            <td className='text-left'>{unaPropiedades.result.plantas}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiOfficeBuilding } title="piso" size={1}  className="align-middle"/> Piso</td>
                            {(unaPropiedades.result.piso === '0')?(<td className='text-left'>PB</td>):
                            (<td className='text-left'>{unaPropiedades.result.piso}</td>)
                            }
                            
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaGBoxOutline  } title="gastos comunes" size={1}  className="align-middle"/> Gastos comunes</td>
                            {(unaPropiedades.result.monedaGC === 'D')?(
                              <td className='text-left'>U$S{formatNumber(unaPropiedades.result.gastosComunes)}</td>
                            ):(
                              <td className='text-left'>${formatNumber(unaPropiedades.result.gastosComunes)}</td>
                            )}
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSortCalendarAscending } title="frecuencia GC" size={1}  className="align-middle"/> Frecuencia GC</td>
                            {(unaPropiedades.result.frecuenciaGastos === 'MENSUAL')?(<td className='text-left'>Mensual</td>):
                            ((unaPropiedades.result.frecuenciaGastos === 'TRIMESTRAL')?(<td className='text-left'>Trimestral</td>):
                            (unaPropiedades.result.frecuenciaGastos === 'SEMESTRAL')?(<td className='text-left'>Semestral</td>):
                            (<td className='text-left'>{unaPropiedades.result.frecuenciaGastos}</td>))}
                            
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline} title="imp. inmobiliario" size={1}  className="align-middle"/> Imp. inmobiliario</td>

                            {(unaPropiedades.result.impuestoInmobiliario === '0')?(<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoInmobiliario)}</td>):
                            ((unaPropiedades.result.impuestoInmobiliario === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoInmobiliario)}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline } title="imp. primaria" size={1}  className="align-middle"/> Imp. primaria</td>

                            {(unaPropiedades.result.impuestoPrimaria === '0')?(<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoPrimaria)}</td>):
                            ((unaPropiedades.result.impuestoPrimaria === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoPrimaria)}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAccountLockOutline } title="portería" size={1}  className="align-middle"/> Portería</td>
                            <td className='text-left'>{(unaPropiedades.result.porteria === '1')?('Si'):('No')}</td>
                        </tr>
                      }

                      
                      
                      
                      
                
                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                    {
                        <tr>
                            <td className='withTd'><Icon path={mdiStove } size={1}  className="align-middle" title="cocina definida"/> Cocina definida</td>
                            <td className='text-left'>{(unaPropiedades.result.cocinaDefinida === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBalcony } title="terraza" size={1}  className="align-middle"/> Terraza</td>
                            <td className='text-left'>{(unaPropiedades.result.terraza === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiWashingMachine } title="terraza lavadero" size={1}  className="align-middle"/> Terraza lavadero</td>
                            <td className='text-left'>{(unaPropiedades.result.terrazaLavadero === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    {
                        <tr>
                            <td className='withTd'><Icon path={mdiShovel } size={1}  className="align-middle" title="jardin"/> Jardin</td>
                            <td className='text-left'>{(unaPropiedades.result.jardin === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGarageOpenVariant } size={1}  className="align-middle" title="cochera"/> Cochera</td>
                            <td className='text-left'>{(unaPropiedades.result.cochera === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGarageVariantLock } title="garage" size={1}  className="align-middle"/> Garage</td>
                            <td className='text-left'>{(unaPropiedades.result.garage === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGrillOutline } title="parrillero propio" size={1}  className="align-middle"/> Parrillero propio</td>
                            <td className='text-left'>{(unaPropiedades.result.parrilleroPropio === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      
                      
                      
                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                    
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRadiator } size={1}  className="align-middle" title="losa radiante"/> Losa radiante</td>
                            <td className='text-left'>{(unaPropiedades.result.losaRadiante === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSunThermometer  } title="calefacción" size={1}  className="align-middle"/> Calefacción</td>
                            <td className='text-left'>{(unaPropiedades.result.calefaccion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGasBurner } title="estufa" size={1}  className="align-middle"/> Estufa</td>
                            <td className='text-left'>{(unaPropiedades.result.estufa === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaPBoxOutline } title="acepta permutas" size={1}  className="align-middle"/> Acepta permutas</td>
                            <td className='text-left'>{(unaPropiedades.result.aceptaPermutas === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaFBoxOutline  } title="acepta financiación" size={1}  className="align-middle"/> Acepta Financiación</td>
                            <td className='text-left'>{(unaPropiedades.result.aceptaFinanciacion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>

            </Col>
      </Row>
      ):(
        /* CASAS*/
        (unaPropiedades.result.barrioPrivado === '1')?(
          /* CASA BARRIO PRIVADO */
          <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap widthRow">
            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable">

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBedKing} size={1}  className="align-middle" title="dormitorios"/> Dormitorios</td>
                            <td className='text-left'>{(unaPropiedades.result.dormitorio)?((unaPropiedades.result.dormitorio.toString().endsWith('0'))?(Math.trunc(unaPropiedades.result.dormitorio)):(unaPropiedades.result.dormitorio)):(null)}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiShower} title="baños" size={1}  className="align-middle"/> Baños</td>
                            <td className='text-left'>{unaPropiedades.result.banos}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRulerSquare} title="metros edificados" size={1}  className="align-middle"/> Metros² edificados</td>
                            <td className='text-left'>{unaPropiedades.result.metrosEdificados}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRulerSquare} title="metros terreno" size={1}  className="align-middle"/> Metros² terreno</td>
                            <td className='text-left'>{unaPropiedades.result.metrosSuperficie}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiCompassOutline } size={1}  className="align-middle" title="orientación"/> Orientación</td>
                            <td className='text-left'>{unaPropiedades.result.orientacion}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBorderInside } title="disposición" size={1}  className="align-middle"/> Disposición</td>

                            {(unaPropiedades.result.disposicion === 'FRENTE')?(<td className='text-left'>Frente</td>):
                            ((unaPropiedades.result.disposicion === 'CONTRAFRENTE')?(<td className='text-left'>Contrafrente</td>):
                            ((unaPropiedades.result.disposicion === 'LATERAL')?(<td className='text-left'>Lateral</td>):
                            (unaPropiedades.result.disposicion === 'INTERIOR'))?(<td className='text-left'>Interior</td>):(
                              <td className='text-left'>{unaPropiedades.result.disposicion}</td>
                            ))
                          }
                            
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiCalendarRange } title="año construcción" size={1}  className="align-middle"/> Año Constr.</td>
                            {(unaPropiedades.result.anoConstruccion === '1')?(<td className='text-left'>----</td>):
                            ((unaPropiedades.result.anoConstruccion === '2')?(<td className='text-left'>Reciclada</td>):
                            (<td className='text-left'>{unaPropiedades.result.anoConstruccion}</td>
                            ))
                            }
                        </tr>
                      }                     
                      
                    </tbody>
                </Table>
            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                    {
                        <tr>
                            
                              {(unaPropiedades.result.plantas === '1')?(
                                <td className='withTd'><Icon path={mdiHomeFloor1} size={1}  className="align-middle" title="plantas"/> Plantas</td>
                                ):
                              ((unaPropiedades.result.plantas === '2')?(<td className='withTd'><Icon path={mdiHomeFloor2} size={1}  className="align-middle" title="plantas"/> Plantas</td>)
                                
                              :((unaPropiedades.result.plantas === '3')?(<td className='withTd'><Icon path={mdiHomeFloor3} size={1}  className="align-middle" title="plantas"/> Plantas</td>):
                              (<td className='withTd'><Icon path={mdiHomeFloor0 } size={1}  className="align-middle" title="plantas"/> Plantas</td>))
                              )}
                              
                            
                            
                            <td className='text-left'>{unaPropiedades.result.plantas}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBoomGate } title="barrio privado" size={1}  className="align-middle"/> Barrio privado</td>
                            <td className='text-left'>{(unaPropiedades.result.barrioPrivado === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaGBoxOutline  } title="gastos comunes" size={1}  className="align-middle"/> Gastos comunes</td>
                            {(unaPropiedades.result.monedaGC === 'D')?(
                              <td className='text-left'>U$S{formatNumber(unaPropiedades.result.gastosComunes)}</td>
                            ):(
                              <td className='text-left'>${formatNumber(unaPropiedades.result.gastosComunes)}</td>
                            )}
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSortCalendarAscending } title="frecuencia GC" size={1}  className="align-middle"/> Frecuencia GC</td>
                            {(unaPropiedades.result.frecuenciaGastos === 'MENSUAL')?(<td className='text-left'>Mensual</td>):
                            ((unaPropiedades.result.frecuenciaGastos === 'TRIMESTRAL')?(<td className='text-left'>Trimestral</td>):
                            (unaPropiedades.result.frecuenciaGastos === 'SEMESTRAL')?(<td className='text-left'>Semestral</td>):
                            (<td className='text-left'>{unaPropiedades.result.frecuenciaGastos}</td>))}
                            
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline} title="imp. inmobiliario" size={1}  className="align-middle"/> Imp. inmobiliario</td>

                            {(unaPropiedades.result.impuestoInmobiliario === '0')?(<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoInmobiliario)}</td>):
                            ((unaPropiedades.result.impuestoInmobiliario === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoInmobiliario)}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline } title="imp. primaria" size={1}  className="align-middle"/> Imp. primaria</td>

                            {(unaPropiedades.result.impuestoPrimaria === '0')?(<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoPrimaria)}</td>):
                            ((unaPropiedades.result.impuestoPrimaria === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoPrimaria)}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAccountLockOutline } title="portería" size={1}  className="align-middle"/> Portería</td>
                            <td className='text-left'>{(unaPropiedades.result.porteria === '1')?('Si'):('No')}</td>
                        </tr>
                      }

                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                    {
                        <tr>
                            <td className='withTd'><Icon path={mdiStove } size={1}  className="align-middle" title="cocina definida"/> Cocina definida</td>
                            <td className='text-left'>{(unaPropiedades.result.cocinaDefinida === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiWashingMachine } title="lavadero" size={1}  className="align-middle"/> Lavadero</td>
                            <td className='text-left'>{(unaPropiedades.result.terrazaLavadero === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    {
                        <tr>
                            <td className='withTd'><Icon path={mdiShovel } size={1}  className="align-middle" title="jardin"/> Jardin</td>
                            <td className='text-left'>{(unaPropiedades.result.jardin === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGarageOpenVariant } size={1}  className="align-middle" title="cochera"/> Cochera</td>
                            <td className='text-left'>{(unaPropiedades.result.cochera === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGarageVariantLock } title="garage" size={1}  className="align-middle"/> Garage</td>
                            <td className='text-left'>{(unaPropiedades.result.garage === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGrillOutline } title="parrillero propio" size={1}  className="align-middle"/> Parrillero propio</td>
                            <td className='text-left'>{(unaPropiedades.result.parrilleroPropio === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRadiator } size={1}  className="align-middle" title="losa radiante"/> Losa radiante</td>
                            <td className='text-left'>{(unaPropiedades.result.losaRadiante === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      
                      
                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSunThermometer  } title="calefacción" size={1}  className="align-middle"/> Calefacción</td>
                            <td className='text-left'>{(unaPropiedades.result.calefaccion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGasBurner } title="estufa" size={1}  className="align-middle"/> Estufa</td>
                            <td className='text-left'>{(unaPropiedades.result.estufa === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaPBoxOutline } title="acepta permutas" size={1}  className="align-middle"/> Acepta permutas</td>
                            <td className='text-left'>{(unaPropiedades.result.aceptaPermutas === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaFBoxOutline  } title="acepta financiación" size={1}  className="align-middle"/> Acepta Financiación</td>
                            <td className='text-left'>{(unaPropiedades.result.aceptaFinanciacion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>

            </Col>
      </Row>
        ):(
          /* CASA */
          <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap widthRow paddingId">
            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable">

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBedKing} size={1}  className="align-middle" title="dormitorios"/> Dormitorios</td>
                            <td className='text-left'>{(unaPropiedades.result.dormitorio)?((unaPropiedades.result.dormitorio.toString().endsWith('0'))?(Math.trunc(unaPropiedades.result.dormitorio)):(unaPropiedades.result.dormitorio)):(null)}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiShower} title="baños" size={1}  className="align-middle"/> Baños</td>
                            <td className='text-left'>{unaPropiedades.result.banos}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRulerSquare} title="metros edificados" size={1}  className="align-middle"/> Metros² edificados</td>
                            <td className='text-left'>{unaPropiedades.result.metrosEdificados}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRulerSquare} title="metros terreno" size={1}  className="align-middle"/> Metros² terreno</td>
                            <td className='text-left'>{unaPropiedades.result.metrosSuperficie}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiCompassOutline } size={1}  className="align-middle" title="orientación"/> Orientación</td>
                            <td className='text-left'>{unaPropiedades.result.orientacion}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBorderInside } title="disposición" size={1}  className="align-middle"/> Disposición</td>

                            {(unaPropiedades.result.disposicion === 'FRENTE')?(<td className='text-left'>Frente</td>):
                            ((unaPropiedades.result.disposicion === 'CONTRAFRENTE')?(<td className='text-left'>Contrafrente</td>):
                            ((unaPropiedades.result.disposicion === 'LATERAL')?(<td className='text-left'>Lateral</td>):
                            (unaPropiedades.result.disposicion === 'INTERIOR'))?(<td className='text-left'>Interior</td>):(
                              <td className='text-left'>{unaPropiedades.result.disposicion}</td>
                            ))
                          }
                            
                        </tr>
                      }                     
                      
                    </tbody>
                </Table>
            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>

                    {
                        <tr>
                            <td className='withTd'><Icon path={mdiCalendarRange } title="año constr." size={1}  className="align-middle"/> Año Constr.</td>
                            {(unaPropiedades.result.anoConstruccion === '1')?(<td className='text-left'>----</td>):
                            ((unaPropiedades.result.anoConstruccion === '2')?(<td className='text-left'>Reciclada</td>):
                            (<td className='text-left'>{unaPropiedades.result.anoConstruccion}</td>
                            ))
                            }
                       </tr>
                    }
                    {
                        <tr>
                              {(unaPropiedades.result.plantas === '1')?(
                                <td className='withTd'><Icon path={mdiHomeFloor1} size={1}  className="align-middle" title="plantas"/> Plantas</td>
                                ):
                              ((unaPropiedades.result.plantas === '2')?(<td className='withTd'><Icon path={mdiHomeFloor2} size={1}  className="align-middle" title="plantas"/> Plantas</td>)
                                
                              :((unaPropiedades.result.plantas === '3')?(<td className='withTd'><Icon path={mdiHomeFloor3} size={1}  className="align-middle" title="plantas"/> Plantas</td>):
                              (<td className='withTd'><Icon path={mdiHomeFloor0 } size={1}  className="align-middle" title="plantas"/> Plantas</td>))
                              )}
                              
                            
                            
                            <td className='text-left'>{unaPropiedades.result.plantas}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline} title="imp. inmobiliario" size={1}  className="align-middle"/> Imp. inmobiliario</td>

                            {(unaPropiedades.result.impuestoInmobiliario === '0')?(<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoInmobiliario)}</td>):
                            ((unaPropiedades.result.impuestoInmobiliario === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoInmobiliario)}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline } title="imp. primaria" size={1}  className="align-middle"/> Imp. primaria</td>

                            {(unaPropiedades.result.impuestoPrimaria === '0')?(<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoPrimaria)}</td>):
                            ((unaPropiedades.result.impuestoPrimaria === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unaPropiedades.result.impuestoPrimaria)}</td>
                            ))
                            }
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiStove } size={1}  className="align-middle" title="cocina definida"/> Cocina definida</td>
                            <td className='text-left'>{(unaPropiedades.result.cocinaDefinida === '1')?('Si'):('No')}</td>
                        </tr>
                      }

                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiWashingMachine } title="lavadero" size={1}  className="align-middle"/> Lavadero</td>
                            <td className='text-left'>{(unaPropiedades.result.terrazaLavadero === '1')?('Si'):('No')}</td>
                        </tr>
                      }

                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody> 
                    {
                        <tr>
                            <td className='withTd'><Icon path={mdiShovel } size={1}  className="align-middle" title="jardin"/> Jardin</td>
                            <td className='text-left'>{(unaPropiedades.result.jardin === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGarageOpenVariant } size={1}  className="align-middle" title="cochera"/> Cochera</td>
                            <td className='text-left'>{(unaPropiedades.result.cochera === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGarageVariantLock } title="garage" size={1}  className="align-middle"/> Garage</td>
                            <td className='text-left'>{(unaPropiedades.result.garage === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGrillOutline } title="parrillero" size={1}  className="align-middle"/> Parrillero</td>
                            <td className='text-left'>{(unaPropiedades.result.parrilleroPropio === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiRadiator } size={1}  className="align-middle" title="losa radiante"/> Losa radiante</td>
                            <td className='text-left'>{(unaPropiedades.result.losaRadiante === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSunThermometer  } title="calefacción" size={1}  className="align-middle"/> Calefacción</td>
                            <td className='text-left'>{(unaPropiedades.result.calefaccion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      
                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                      
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGasBurner } title="estufa" size={1}  className="align-middle"/> Estufa</td>
                            <td className='text-left'>{(unaPropiedades.result.estufa === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaPBoxOutline } title="acepta permutas" size={1}  className="align-middle"/> Acepta permutas</td>
                            <td className='text-left'>{(unaPropiedades.result.aceptaPermutas === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaFBoxOutline  } title="acepta financiación" size={1}  className="align-middle"/> Acepta Financiación</td>
                            <td className='text-left'>{(unaPropiedades.result.aceptaFinanciacion === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>

            </Col>
      </Row>

        )
      )}

      {(unaPropiedades.result.amenities === '1')?(
      <>
      <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding widthRow paddingId">
            <Col xs={12} md={8} lg={8} xl={8}>
              <Row className="justify-content-md-center">
                <Col xs lg="2" align="center">
                    <h2><font color="#23395B">Amenities</font></h2>
                </Col>
              </Row>
            </Col>
        </Row>

        <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap widthRow paddingId">
            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable" >

                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSunglasses   } size={1}  className="align-middle" title="solarium"/> Solarium</td>
                            <td className='text-left'>{(unaPropiedades.result.solarium === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiPool } title="piscina interior" size={1}  className="align-middle"/> Piscina interior</td>
                            <td className='text-left'>{(unaPropiedades.result.piscinaInterior === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiPool } title="piscina exterior" size={1}  className="align-middle"/> Piscina exterior</td>
                            <td className='text-left'>{(unaPropiedades.result.piscinaExterior === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiBilliards } title="play room" size={1}  className="align-middle"/> Play room</td>
                            <td className='text-left'>{(unaPropiedades.result.playRoom === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>
            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiWeightLifter } size={1}  className="align-middle" title="gym"/> Gym</td>
                            <td className='text-left'>{(unaPropiedades.result.gym === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSunThermometerOutline  } title="sauna" size={1}  className="align-middle"/> Sauna</td>
                            <td className='text-left'>{(unaPropiedades.result.sauna === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiWaves } title="jacuzzi" size={1}  className="align-middle"/> Jacuzzi</td>
                            <td className='text-left'>{(unaPropiedades.result.jacuzzi === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiGrillOutline } title="barbacoa" size={1}  className="align-middle"/> Barbacoa</td>
                            <td className='text-left'>{(unaPropiedades.result.barbacoa === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiTumbleDryer } size={1}  className="align-middle" title="laundry"/> Laundry</td>
                            <td className='text-left'>{(unaPropiedades.result.laundry === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiSoccerField } title="canchas" size={1}  className="align-middle"/> Canchas</td>
                            <td className='text-left'>{(unaPropiedades.result.canchas === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiUmbrellaBeachOutline } title="servicio de playa" size={1}  className="align-middle"/> Servicio de playa</td>
                            <td className='text-left'>{(unaPropiedades.result.servicioDePlaya === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiVacuumOutline } title="servicio de mucama" size={1}  className="align-middle"/> Servicio de mucama</td>
                            <td className='text-left'>{(unaPropiedades.result.servicioDeMucama === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                    </tbody>
                </Table>

            </Col>

            <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >
                <Table striped bordered hover>
                    <tbody>
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiTheater } size={1}  className="align-middle" title="cine"/> Cine</td>
                            <td className='text-left'>{(unaPropiedades.result.cine === '1')?('Si'):('No')}</td>
                        </tr>
                      }
                      {
                        <tr>
                            <td className='withTd'><Icon path={mdiWifi } title="wifi" size={1}  className="align-middle"/> Wifi</td>
                            <td className='text-left'>{(unaPropiedades.result.wifi === '1')?('Si'):('No')}</td>
                        </tr>
                      }

                      
                    </tbody>
                </Table>

            </Col>
      </Row>
      </>
      ):(null)}
</>
  ):(null)} 

      <MapPropiedad longitud={unaPropiedades.result.longitud} latitud={unaPropiedades.result.latitud}/>

      <ModalFotos
        show={modalShow}
        onHide={() => setModalShow(false)}
        className='modal fondoModal'
        src={unaPropiedades.result.image} precio={unaPropiedades.result.precio} id={id} tipo='c'
        modalShow={modalShow} setModalShow={setModalShow}
      />
      
      <Footer/>
  </Row>

  
  )
}

export default PropiedadId