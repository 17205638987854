//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import CarouselPropiedad from '../Carousel/CarouselPropiedad';
import Contacto from '../Contactos/Contacto';
import Footer from '../Footer';
import Icon from '@mdi/react'
import { mdiRulerSquare,mdiAlphaIBoxOutline,mdiAlphaPBoxOutline,mdiAlphaFBoxOutline } from '@mdi/js';
import MapPropiedad from '../MapPropiedad';
import '../../css/contacto.css'

import {useDispatch, useSelector} from 'react-redux'
import NavPropiedad from '../Nav/NavPropiedad';
import CarouselPropiedad2 from '../Carousel/CarouselPropiedad2';
import { filtroUnTerrenoOculto } from '../../redux/redux-front/terrenos/UnTerrenoOcultoDucks';

import ModalFotos from '../Propiedades/ModalFotos';

const TerrenoOculto = (props) => {

  const dispatch = useDispatch()

  const unTerrenoOculto = useSelector(store => store.filtroUnTerrenoOculto)
  
  React.useEffect(() => {
      const fetchData = () => {
        if(props.id !== ''){
          dispatch(filtroUnTerrenoOculto(props.id))
        }
      }
    fetchData()

  },[dispatch]) 

  let [error_msg, setError_msg] = React.useState('') 
  let [cargando_msg, setCargando_msg] = React.useState('')


  React.useEffect(() => {
    
    
      if(unTerrenoOculto.isFetching === true){
          setCargando_msg('Cargando')
      }

      if(unTerrenoOculto.result.result !== undefined && unTerrenoOculto.isFetching === false && unTerrenoOculto.status === "error"){
          setCargando_msg('')
          setError_msg(unTerrenoOculto.results.error_msg)
          
      }

  },[unTerrenoOculto])


  let msgError = []
  if(error_msg !== ''){
       msgError.push(<div className="alert alert-danger">{error_msg}</div>)
  }

  let msgCargando = []
  if(cargando_msg !== ''){
      msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
  } 

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num)
  }

  const [modalShow, setModalShow] = React.useState(false);
    
  return (
    <>
    
    <Row xs={12} md={12} lg={12} xl={12} className='gx-0'>

    <NavPropiedad/>

  {(unTerrenoOculto.result !== null)?(
  <>
    <Row xs={12} sm={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
      <Col xs={12} sm={12} md={8} lg={8} xl={8} >
        <div id="imageNone"> 
          <CarouselPropiedad src={unTerrenoOculto.result.image} precio={unTerrenoOculto.result.precio} id={props.id} tipo='t' modalShow={modalShow} setModalShow={setModalShow}/>
        </div>
        <div id="ipad" className="ms-auto d-block d-md-none">
          <CarouselPropiedad2 src={unTerrenoOculto.result.image} precio={unTerrenoOculto.result.precio} id={props.id} tipo='t' modalShow={modalShow} setModalShow={setModalShow}/>
        </div>
      </Col>

      <Col xs={12} md={5} lg={5} xl={4} className="paddingContacto" id="contacto">

          <Contacto className='rowContacto' tipo='t'/>

      </Col>
    </Row>


  {(unTerrenoOculto.result.boolDescripcion === '1')?(
  <>
    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
        <Col xs={12} md={8} lg={8} xl={8} >
          <Row className="justify-content-md-center">
            <Col xs lg="2" align="center">
                <h2><font color="#23395B">Descripción</font></h2>
            </Col>
          </Row>
        </Col>
  </Row>
  
    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingId">
        <Col xs={12} md={8} lg={8} xl={8} className="paddingMobile p-md-4">
          <Row className="justify-content-md-center">
            <Col xs={12} md={8} lg={8} xl={8} align="left" className='widht'>
            <p style={{ whiteSpace: "pre-line" }}><font color="#23395B">{unTerrenoOculto.result.descripcion}</font></p>                
            </Col>
          </Row>
        </Col>
  </Row>
  </>
  ):(null)}
    
    
    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter widthRow paddingId">
        <Col xs={12} md={8} lg={8} xl={8} >
          <Row className="justify-content-md-center">
            <Col xs lg="2" align="center">
                <h2><font color="#23395B">Características</font></h2>
            </Col>
          </Row>
        </Col>
    </Row>

    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap paddingId">{/* widthRow */}
        <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable" >

            <Table striped bordered hover>
                <tbody>
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiRulerSquare} size={1}  className="align-middle" title="metros"/> Métros²</td>
                        <td className='text-left'>{unTerrenoOculto.result.metros}</td>
                    </tr>
                  }
                </tbody>


            </Table>
        </Col>

        <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

            <Table striped bordered hover>
                <tbody>
                  {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline} title="imp. inmobiliario" size={1}  className="align-middle"/> Imp. inmobiliario</td>

                            {(unTerrenoOculto.result.impuestoInmobiliario === '0')?(<td className='text-left'>${formatNumber(unTerrenoOculto.result.impuestoInmobiliario)}</td>):
                            ((unTerrenoOculto.result.impuestoInmobiliario === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unTerrenoOculto.result.impuestoInmobiliario)}</td>
                            ))
                            }
                        </tr>
                      }
                </tbody>
            </Table>
        </Col>

        <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

            <Table striped bordered hover>
                <tbody>
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaPBoxOutline} size={1}  className="align-middle" title="acepta permutas"/> Acepta Permutas</td>
                        <td className='text-left'>{(unTerrenoOculto.result.aceptaPermutas === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                </tbody>
            </Table>
        </Col>

        <Col md={4} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

            <Table striped bordered hover>
                <tbody>
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaFBoxOutline} size={1}  className="align-middle" title="acepta financiacion"/> Acepta Financiación</td>
                        <td className='text-left'>{(unTerrenoOculto.result.aceptaFinanciacion === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                </tbody>
            </Table>
        </Col>

    </Row>
    </>
  ):(null)}

  <MapPropiedad longitud={unTerrenoOculto.result.longitud} latitud={unTerrenoOculto.result.latitud}/>
  
  <ModalFotos
        show={modalShow}
        onHide={() => setModalShow(false)}
        className='modal fondoModal'
        src={unTerrenoOculto.result.image} precio={unTerrenoOculto.result.precio} id={props.id} tipo='c'
        modalShow={modalShow} setModalShow={setModalShow}
  />
  
  <Footer/>

</Row>
</> 

  )
}

export default TerrenoOculto
