import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import 'bootstrap/dist/css/bootstrap.css';
import './css/app.css'
import './css/card.css'
import './css/carouselPropiedades.css'
import './css/contacto.css'
import './css/filter.css'
import './css/footer.css'
import './css/leaflet.css'
import './css/navBar.css'
import './css/portada.css'
import './css/propiedades.css'
import './css/variables.css'
import './css/whatsapp.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App className='fondo'/>
);


