//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE

import React from 'react'
import { Col,Row } from 'react-bootstrap';
import Filter from './Filter';
import NavBar from './Nav/NavBar';
import Propiedades from './Propiedades/Propiedades';
import '../css/footer.css'
import '../css/filter.css'
import '../css/propiedades.css'
import Footer from './Footer';
import Campos from './Propiedades/Campos';
import Terrenos from './Propiedades/Terrenos';
import Contactos from './Contactos/Contactos';

const General = () => {

const [propiedadSelect,setPropiedadSelect] = React.useState(`${localStorage.getItem('type')}`)

const [datos, setDatos] = React.useState(null)

  React.useEffect(() => {
    if(localStorage.getItem('type')){
      setPropiedadSelect(localStorage.getItem('type'))
      document.getElementById(`${localStorage.getItem('type')}`).classList.add("active")
    }
    
  }, [localStorage.getItem('type')]) 
  
  return (

    <>
      <Row className='gx-0'>
          <NavBar propiedadSelect={propiedadSelect} setPropiedadSelect={setPropiedadSelect}/>
       

       {(propiedadSelect !== 'CO')?(
        /* ALL */
        <Row xs={12} sm={12} md={12} lg={12} xl={12} className="gx-0 background-filter general justify-content-center paddingMobile" >

            {/* FILTER */}
            <Col xs={12} sm={12} md={4} lg={3} xl={3} xxl={3} className=" paddingFiltro">
               <Filter  propiedadSelect = {propiedadSelect} setPropiedadSelect = {setPropiedadSelect} datos={datos} setDatos={setDatos}/>
            </Col>

            {/* PROPIEDADES */}
            <Col xs={12} sm={12} md={8} lg={9} xl={9} xxl={9} className="px-lg-5 px-md-5 paddingPropiedades">

            
              {(propiedadSelect === 'C' || propiedadSelect === 'A')?
              ((<Propiedades tipo={propiedadSelect} datos={datos} setDatos={setDatos}/>)):
               ((propiedadSelect === 'T')?(<Terrenos tipo={propiedadSelect} datos={datos} setDatos={setDatos}/>):((propiedadSelect === 'CA')?(<Campos tipo={propiedadSelect} datos={datos} setDatos={setDatos}/>):(null)))

              }
            </Col>
        </Row>

       ):(<Contactos />)
       }
      


        { /* FOOTER */ }
            <Footer/>
    </Row>
  

    </>
  )
}

export default General