//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE

import React from 'react'
import { MapContainer,TileLayer,Circle } from "react-leaflet";
import "../css/leaflet.css";


const MapPropiedad = ({longitud,latitud}) => {

    let center = {
      lat: -34.910635,
      lng: -54.963216,
    }
    const [position, setPosition] = React.useState(center)
    const mapRef = React.useRef(null)



    React.useEffect(() => {

      if(longitud && latitud){

        let center2 = {
          lat: Number(latitud),
          lng: Number(longitud),
        }
        setPosition(center2)

        mapRef.current.setView(center2, 15)
      
      }
    }, [longitud,latitud ])
      
    const fillBlueOptions = { fillColor: 'blue' }

  return (


    <MapContainer center={center} zoom={15} scrollWheelZoom={false} className="posicion" ref={mapRef}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        
        <Circle center={position} pathOptions={fillBlueOptions} radius={200} />
          
    </MapContainer>


  )
}

export default MapPropiedad