import axios from 'axios'
import {urlApi} from '../../services/apirest'


//constantes
const dataInicial = {
    status : "",
    result : [],
    conectado : false,
    isFetching : false
}

//Types
const PASS_EXITO = 'PASS_EXITO'
const PASS_ERROR = 'PASS_ERROR'
const PASS_ESPERANDO = 'PASS_ESPERANDO'


//reducer
export default function verifyPassReducer(state = dataInicial, action){
    switch(action.type){

        case PASS_ESPERANDO:
            return {
                ...state,
                isFetching: true,
                conectado : false
            }

        case PASS_EXITO:
            return {...state, 
                isFetching: false, 
                status: action.payload.status,
                result: action.payload,
                conectado : true
            }

        case PASS_ERROR:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                result: action.payload.result
            }
        
        default:
            return state
    }
}

//acciones
export const verifyPass = (tipo,path,pass) => async (dispatch) => {

    const url = urlApi + tipo
    dispatch({
        type: PASS_ESPERANDO,
    })

    try {

        let datos = {
            path:path,
            pass:pass
        }

        const res = await axios.post(url, datos)

        if(res.data.status === "ok"){

            dispatch({
                type : PASS_EXITO,
                payload : {status: res.data.status,
                            result:res.data.result
                            }
            })

            if(`${tipo}` === 'terrenoInactivo'){
                localStorage.setItem('id',JSON.stringify(res.data.result.terrenoId))
                localStorage.setItem(`${tipo}`,JSON.stringify(res.data.result.token))
            }
            if(`${tipo}` === 'campoInactivo'){
                localStorage.setItem('id',JSON.stringify(res.data.result.campoId))
                localStorage.setItem(`${tipo}`,JSON.stringify(res.data.result.token))
            }
            if(`${tipo}` === 'propiedadInactivo'){
                localStorage.setItem('id',JSON.stringify(res.data.result.propiedadId))
                localStorage.setItem(`${tipo}`,JSON.stringify(res.data.result.token))
            }
            

        }else{

            dispatch({
                type: PASS_ERROR,
                payload: {status: res.data.status,
                            result: res.data.result
                        }
        })
        }
     

    }catch (error) {
        dispatch({
            type: PASS_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
 
} 