//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import '../css/whatsapp.css'
import Icon from '@mdi/react'
import {mdiWhatsapp} from '@mdi/js';

const Whatsapp = () => {
  return (
    <a href='https://api.whatsapp.com/send?phone=598099933330' className='btn-whatsapp' target="_blank">
        <Icon path={mdiWhatsapp } size={1.7} />
    </a>
  )
}

export default Whatsapp