//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Button,Col,Row} from 'react-bootstrap'
import '../../css/propiedades.css'
import {useDispatch, useSelector} from 'react-redux'
import {filtroTerreno, siguientePaginaFiltroTerreno, anteriorPaginaFiltroTerreno} from '../../redux/redux-front/terrenos/FiltroTerrenoDucks'
import Terreno from './Terreno'
import NotFound from '../NotFound'
import SpinnerComponent from '../SpinnerComponent'

const Terrenos = ({tipo,datos}) => {
  
    const dispatch = useDispatch()

    const [propiedadTipo, setPropiedadTipo] = React.useState('')

    React.useEffect(() => {
        setPropiedadTipo(tipo)
      },[tipo]) 


    const terrenos = useSelector(store => store.filtroTerreno)
    const next = useSelector(store => store.filtroTerreno.next)
    const previous = useSelector(store => store.filtroTerreno.previous) 

    let precioDesdeT
    let precioHastaT
    let metrosDesdeT
    let metrosHastaT

    React.useEffect(() => {

        if(propiedadTipo === 'T'){

            precioDesdeT = 'SD'
            precioHastaT = 'SD'
            metrosDesdeT = 'SD'
            metrosHastaT = 'SD'

          //Precio
          if(datos.precio === "0-30"){
            precioDesdeT = 0
            precioHastaT = 30
            
          }
          if(datos.precio === "30-60"){
            precioDesdeT = 30000
            precioHastaT = 60000
          }
          if(datos.precio === "60-120"){
            precioDesdeT = 60000
            precioHastaT = 120000
          }
          if(datos.precio === "+120"){
            precioDesdeT = 120000
            precioHastaT = 100000000
          }
          if(datos.precio === "SD"){
            precioDesdeT = 'SD'
            precioHastaT = 'SD'
          }

          //Metros
          if(datos.metros === "0-500"){
            metrosDesdeT = 0
            metrosHastaT = 500
          }
          if(datos.metros === "500-1000"){
            metrosDesdeT = 500
            metrosHastaT = 1000
          }
          if(datos.metros === "1000-1500"){
            metrosDesdeT = 1000
            metrosHastaT = 1500
          }
          if(datos.metros === "+1500"){
            metrosDesdeT = 1500
            metrosHastaT = 100000000
          }
          if(datos.metros === "SD"){
            metrosDesdeT = 'SD'
            metrosHastaT = 'SD'
          }

            const fetchData = () => {
            
                dispatch(filtroTerreno(metrosDesdeT,metrosHastaT,precioDesdeT,precioHastaT,datos.ubicacion,datos.orderBy))    
            }
            fetchData()
        }
        
    },[dispatch,propiedadTipo,datos])

    const scrollUp = () =>{
      let currentScroll = document.documentElement.scrollTop
      if(currentScroll > 0){
        window.scrollTo(0,0)
      }
    }


    const anterior = () =>{
      if(propiedadTipo === 'T'){

        precioDesdeT = 'SD'
        precioHastaT = 'SD'
        metrosDesdeT = 'SD'
        metrosHastaT = 'SD'

      //Precio
      if(datos.precio === "0-30"){
        precioDesdeT = 0
        precioHastaT = 30
        
      }
      if(datos.precio === "30-60"){
        precioDesdeT = 30000
        precioHastaT = 60000
      }
      if(datos.precio === "60-120"){
        precioDesdeT = 60000
        precioHastaT = 120000
      }
      if(datos.precio === "+120"){
        precioDesdeT = 120000
        precioHastaT = 100000000
      }
      if(datos.precio === "SD"){
        precioDesdeT = 'SD'
        precioHastaT = 'SD'
      }

      //Metros
      if(datos.metros === "0-500"){
        metrosDesdeT = 0
        metrosHastaT = 500
      }
      if(datos.metros === "500-1000"){
        metrosDesdeT = 500
        metrosHastaT = 1000
      }
      if(datos.metros === "1000-1500"){
        metrosDesdeT = 1000
        metrosHastaT = 1500
      }
      if(datos.metros === "+1500"){
        metrosDesdeT = 1500
        metrosHastaT = 100000000
      }
      if(datos.metros === "SD"){
        metrosDesdeT = 'SD'
        metrosHastaT = 'SD'
      }
      dispatch(anteriorPaginaFiltroTerreno(metrosDesdeT,metrosHastaT,precioDesdeT,precioHastaT,datos.ubicacion,datos.orderBy))
      scrollUp()
    }
    }

    const siguiente = () => {
      if(propiedadTipo === 'T'){

        precioDesdeT = 'SD'
        precioHastaT = 'SD'
        metrosDesdeT = 'SD'
        metrosHastaT = 'SD'

      //Precio
      if(datos.precio === "0-30"){
        precioDesdeT = 0
        precioHastaT = 30
        
      }
      if(datos.precio === "30-60"){
        precioDesdeT = 30000
        precioHastaT = 60000
      }
      if(datos.precio === "60-120"){
        precioDesdeT = 60000
        precioHastaT = 120000
      }
      if(datos.precio === "+120"){
        precioDesdeT = 120000
        precioHastaT = 100000000
      }
      if(datos.precio === "SD"){
        precioDesdeT = 'SD'
        precioHastaT = 'SD'
      }

      //Metros
      if(datos.metros === "0-500"){
        metrosDesdeT = 0
        metrosHastaT = 500
      }
      if(datos.metros === "500-1000"){
        metrosDesdeT = 500
        metrosHastaT = 1000
      }
      if(datos.metros === "1000-1500"){
        metrosDesdeT = 1000
        metrosHastaT = 1500
      }
      if(datos.metros === "+1500"){
        metrosDesdeT = 1500
        metrosHastaT = 100000000
      }
      if(datos.metros === "SD"){
        metrosDesdeT = 'SD'
        metrosHastaT = 'SD'
      }
      dispatch(siguientePaginaFiltroTerreno(metrosDesdeT,metrosHastaT,precioDesdeT,precioHastaT,datos.ubicacion,datos.orderBy))
      scrollUp()
    }
    }
    
    let [error_msg, setError_msg] = React.useState('') 
    let [cargando_msg, setCargando_msg] = React.useState('')


    React.useEffect(() => {
         
        if(terrenos.isFetching === true){
            setCargando_msg('Cargando')
        }

        if(terrenos.isFetching === false && terrenos.status === "error"){
            setCargando_msg('')
            setError_msg(terrenos.results.error_msg)
            
        }

    },[terrenos])


    let msgError = []
    if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    let msgCargando = []
    if(cargando_msg !== ''){
        msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
    } 


  return (
    <>
        {(terrenos.isFetching === true && terrenos.status === '') ? (null

              /* <SpinnerComponent/> */
        
        ) :( 
    <>

    <div className="container d-flex justify-content-center h-100">
        <Col >

        <Row className="g-0" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        {
            (terrenos.result.length !== 0)?(
            terrenos.result.result.datos !== null ? (
                terrenos.status === 'ok' &&
                terrenos.result.result.datos.map((item)=>(
                <Col xs={12} sm={9} md={10} lg={5} xl={4} xxl={4} key={item.terrenoId} className="px-sm-2 px-lg-2 px-md-2 colPropiedad">
                    <Terreno id={item.terrenoId}  image={item.image} metros={item.metros} precio={item.precio} ubicacion={item.ubicacion} ubicacionAux={item.ubicacionAux}/>
                </Col>  
            ))

            ):(
              <>
                <NotFound/>
                </>
            )
            ):(null)
        }
        </Row>
      
        
        <>

         <Row className="justify-content-center p-0 m-0">
            <Col md={3} lg={3} className="p-3"></Col>
            <Col xs ={12} md={6} lg={6} className="p-3">
                <Row className="justify-content-center ">


                
                {
                (terrenos.previous !== null)?(
                (previous!==0) ? (
                    <Col md={2} xs={4} lg={2}>
                        <Button style={{ 'backgroundColor': '#443E38', 'border': 'white'}}  onClick={( ) =>  anterior()} >
                            Anterior
                        </Button>
                    </Col>
                ):(null)
                ):(null)}
               

                
                {
                (terrenos.next !== null)?(
                (next !== null) ? (
                    (next) ? (
                    <Col md={2} xs={2} lg={2} >
                        <Button style={{ 'backgroundColor': '#443E38', 'border': 'white'}} onClick={( ) => siguiente() } >
                            Siguiente
                        </Button>
                    </Col>
                ):null
                ):null
                ):(null)}

                    
                </Row>
            </Col>
            <Col md={3} className="p-3"></Col> 
        </Row> 
        </>
        </Col>
        
    </div>
    
    </>
         )} 
    </>
  )
}

export default Terrenos