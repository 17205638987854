//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Button,Row,Col,Form } from 'react-bootstrap';
import { mdiLock } from '@mdi/js'
import Icon from '@mdi/react'
import CasaAptOculta from './CasaAptOculta';
import CampoOculto from './CampoOculto';
import TerrenoOculto from './TerrenoOculto';
import {useHistory} from 'react-router-dom'

import {verifyPass} from '../../redux/redux-front/PassDucks'

const PropiedadOculta = () => {

    const [pass, setPass] = React.useState('')
    let msgError = []

    const {path,id} = useParams();
    const tipoProp = path.charAt(0);
 
    const dispatch = useDispatch();

    React.useEffect(() => {
        localStorage.clear()
    },[]) 


    const handlePass = (e) => {

        localStorage.removeItem("id")

        e.preventDefault()

        if(tipoProp === 'T'){
            localStorage.removeItem("id")
            localStorage.removeItem("terrenoInactivo")
            dispatch(verifyPass('terrenoInactivo',path,pass))
        }else if(tipoProp === 'C'){
            localStorage.removeItem("id")
            localStorage.removeItem("campoInactivo")
            dispatch(verifyPass('campoInactivo',path,pass))
        }else if(tipoProp === 'P'){
            localStorage.removeItem("id")
            localStorage.removeItem("propiedadInactivo")
            dispatch(verifyPass('propiedadInactivo',path,pass))
        }


        e.target.reset()
        setPass('')

    }

    let [error_msg, setError_msg] = React.useState('') 
    let [psConnect, setPsConnect] = React.useState(false) 

    const resultadoPass = useSelector((store) => store.verifyPass)

    const history = useHistory()
    React.useEffect(() => {
        if(resultadoPass.status === "error"){
            setError_msg(resultadoPass.result.error_msg)
        }
        if(resultadoPass.status === "ok"){

            if(tipoProp === 'T'){ 
                if(id !== resultadoPass.result.result.terrenoId){
                    setPsConnect(false)
                }else{
                    setPsConnect(resultadoPass.conectado)
                }
            }else if(tipoProp === 'C'){
                if(id !== resultadoPass.result.result.campoId){
                    setPsConnect(false)
                }else{
                    setPsConnect(resultadoPass.conectado)
                }
            }else if(tipoProp === 'P'){
                if(id !== resultadoPass.result.result.propiedadId){
                    setPsConnect(false)
                }else{
                    setPsConnect(resultadoPass.conectado)
                }
            }

        }
    }, [resultadoPass])

    if(error_msg !== ''){
        msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }


  return (
    <>
    {(psConnect === false) ? (
    <>

    <Row className="justify-content-center gx-0">
        <Col sm={12} md={12} lg={12} xs={11} xl={10} xxl={6}>
        <Form onSubmit={handlePass} >
            <Row className="justify-content-center p-4">
                <Col sm={12} md={12} lg={12} xs={12} xl={10} xxl={6} className="text-center">
                <h3>Ingrese clave</h3>
                </Col>
            </Row>
            <Row className="justify-content-center p-4">
                <Col sm={12} md={12} lg={12} xs={12} xl={10} xxl={6}>
                    <Row className="justify-content-center" xs={1}>
                        <Col md={1} xs={1}>
                        <Form.Group>
                        <Icon path={mdiLock } size={1}  className="align-middle mb-3"/>
                        </Form.Group>
                        </Col>

                        <Col md={9} xs={11} sm={10} lg={10} xxl={20} xl={10}>
                        <Form.Control type="password" className="mb-3" onChange={e => setPass(e.target.value)} value={pass}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="justify-content-center p-1">
              <Col md={4} className="text-center ">
                  <Button variant="colorBtn" size="lg" type="submit">
                      Ingresar
                  </Button>
              </Col>
            </Row>

            {msgError}

        </Form>
        </Col>
    </Row>
    
    </>
    
    
    ):( 
        <>
        {(tipoProp === 'P')?(
            (resultadoPass.status === "ok")?
            (<CasaAptOculta id={resultadoPass.result.result.propiedadId}/>):
            (null)
        ):(
            (tipoProp === 'C')?(
                (resultadoPass.status === "ok")?
                (<CampoOculto id={resultadoPass.result.result.campoId}/>):
                (null)
            ):(
                (tipoProp === 'T')?(
                    (resultadoPass.status === "ok")?
                    (<TerrenoOculto id={resultadoPass.result.result.terrenoId}/>):
                    (null)
                ):(null)
            )
        )}
        </>

    )}
    </>
  ) 
}

export default PropiedadOculta