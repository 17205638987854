//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import Icon from '@mdi/react'
import { mdiCellphoneBasic,mdiEmailOutline,mdiFacebook,mdiInstagram      } from '@mdi/js';
import { Col, Row, Stack } from 'react-bootstrap';
import '../css/footer.css'
import '../css/filter.css'



const Footer = () => {
  return (

    <Row className='footer-general'>
    
        <Col xs={12} md={8} lg={8}>
          <Row className='nombreFooter'> 
            <Stack className='nombreFooter' direction="horizontal" gap={2}><div  className='WillFooter'>WILL</div><div className='NegociosFooter'>Negocios Inmobiliarios</div></Stack>
          </Row>
        </Col>

        
        <Col xs={12} md={4} lg={4}>
          <Row className='iconosFooter'> 
      
              <Col xs={2} md={1} lg={1} className="p-0 email"><a href="mailto:info@will.com.uy?Subject=Consulta%20desde%20web" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiEmailOutline } size={1.1}/></a></Col>
              <Col xs={2} md={1} lg={1} className="p-0 cel"><a href="tel:099933330" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiCellphoneBasic } size={1.1}/></a></Col>
              <Col xs={2} md={1} lg={1} className="p-0 email"><a href="https://www.facebook.com/inmobiliariaWill" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiFacebook } size={1.1}/></a></Col>
              <Col xs={2} md={1} lg={1} className="p-0 cel"><a href="https://www.instagram.com/inmobiliariaWill" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiInstagram } size={1.1}/></a></Col>
      
          </Row>
        </Col>
    </Row>


  )
}

export default Footer