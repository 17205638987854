//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE



import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import CarouselPropiedad from '../Carousel/CarouselPropiedad';
import Contacto from '../Contactos/Contacto';
import Footer from '../Footer';
import Icon from '@mdi/react'
import { mdiWaterPump ,mdiAlphaHBoxOutline,mdiAlphaTBoxOutline,mdiWave,mdiWaves,mdiWaterCircle,mdiForest,mdiHomeOutline,mdiWaterWellOutline,mdiLightbulbOutline,mdiPhoneClassic,mdiAlphaIBoxOutline,mdiAlphaPBoxOutline,mdiAlphaFBoxOutline } from '@mdi/js';
import MapPropiedad from '../MapPropiedad';
import '../../css/contacto.css'
import {filtroUnCampoOculto} from '../../redux/redux-front/campos/UnCampoOculto'

import {useDispatch, useSelector} from 'react-redux'
import NavPropiedad from '../Nav/NavPropiedad';
import CarouselPropiedad2 from '../Carousel/CarouselPropiedad2';
import ModalFotos from '../Propiedades/ModalFotos';

const CampoOculto = (props) => {

  const dispatch = useDispatch()

    const unCampoOculto = useSelector(store => store.filtroUnCampoOculto)

    React.useEffect(() => {
        const fetchData = () => {
          if(props.id !== ''){
            dispatch(filtroUnCampoOculto(props.id))
          }
          
        }
      fetchData()
  
    },[dispatch]) 

    let [error_msg, setError_msg] = React.useState('') 
    let [cargando_msg, setCargando_msg] = React.useState('')


    React.useEffect(() => {
         
        
        if(unCampoOculto.isFetching === true){
            setCargando_msg('Cargando')
        }

        if(unCampoOculto.result.result !== undefined && unCampoOculto.isFetching === false && unCampoOculto.status === "error"){
            setCargando_msg('')
            setError_msg(unCampoOculto.results.error_msg)
            
        }

    },[unCampoOculto])


    let msgError = []
    if(error_msg !== ''){
         msgError.push(<div className="alert alert-danger">{error_msg}</div>)
    }

    let msgCargando = []
    if(cargando_msg !== ''){
        msgCargando.push(<div className="alert alert-danger">{cargando_msg}</div>)
    } 

    const formatNumber = (num) => {
      return new Intl.NumberFormat().format(num)
    }

    const [modalShow, setModalShow] = React.useState(false);

  return (
    <Row className='gx-0'>

    <NavPropiedad />

{(unCampoOculto.result !== null)?(
  <>
    <Row xs={12} sm={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
      <div id="imageNone">
          <CarouselPropiedad src={unCampoOculto.result.image} precio={unCampoOculto.result.precio} id={props.id} tipo='c' modalShow={modalShow} setModalShow={setModalShow}/>
      </div>
      <div id="ipad" className="ms-auto d-block d-md-none">
          <CarouselPropiedad2 src={unCampoOculto.result.image} precio={unCampoOculto.result.precio} id={props.id} tipo='c' modalShow={modalShow} setModalShow={setModalShow}/>
      </div>
      </Col>

      <Col xs={12} md={5} lg={5} xl={4} className="paddingContacto" id="contacto">

          <Contacto className='rowContacto' tipo='c'/>

      </Col>
    </Row>


    {(unCampoOculto.result.boolDescripcion === '1')?(
    <>
    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter paddingId">
        <Col xs={12} md={8} lg={8} xl={8}>
          <Row className="justify-content-md-center">
            <Col xs lg="2" align="center">
                <h2><font color="#23395B">Descripción</font></h2>
            </Col>
          </Row>
        </Col>
    </Row>
  
    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingId">
        <Col xs={12} md={8} lg={8} xl={8} className="paddingMobile p-md-4">
          <Row className="justify-content-md-center">
          <Col xs={12} md={8} lg={8} xl={8} align="left" className='widht'>
                <p style={{ whiteSpace: "pre-line" }}><font color="#23395B">{unCampoOculto.result.descripcion}</font></p>
            </Col>
          </Row>
        </Col>
    </Row>
    </>
    ):(null)}
    
 
    
    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter widthRow paddingId">
        <Col xs={12} md={8} lg={8} xl={8}>
          <Row className="justify-content-md-center">
            <Col xs lg="2" align="center">
                <h2><font color="#23395B">Características</font></h2>
            </Col>
          </Row>
        </Col>
    </Row>

    <Row xs={12} md={12} lg={12} xl={12} className="gx-0 background-filter padding paddingMap paddingId">
        <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable" >

            <Table striped bordered hover>
                <tbody>
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaHBoxOutline } size={1}  className="align-middle" title="hectáreas"/> Hectáreas</td>
                        <td className='text-left'>{unCampoOculto.result.hectarias}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaTBoxOutline } size={1}  className="align-middle" title="tipo campo"/> Tipo campo</td>
                        <td className='text-left'>{unCampoOculto.result.tipoCampo}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiWave } size={1}  className="align-middle" title="cañada"/> Cañada</td>
                        <td className='text-left'>{(unCampoOculto.result.canada === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiWaves } size={1}  className="align-middle" title="arroyo"/> Arroyo</td>
                        <td className='text-left'>{(unCampoOculto.result.arroyo === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                </tbody>
            </Table>
        </Col>

        <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

            <Table striped bordered hover>
                <tbody>
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiWaterCircle } size={1}  className="align-middle" title="aguada"/> Aguada</td>
                        <td className='text-left'>{(unCampoOculto.result.aguada === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiForest } size={1}  className="align-middle" title="monte"/> Monte</td>
                        <td className='text-left'>{(unCampoOculto.result.monte === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiHomeOutline } size={1}  className="align-middle" title="vivienda"/> Vivienda</td>
                        <td className='text-left'>{(unCampoOculto.result.vivienda === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiWaterWellOutline } size={1}  className="align-middle" title="pozo"/> Pozo</td>
                        <td className='text-left'>{(unCampoOculto.result.pozo === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                </tbody>
            </Table>
        </Col>

        <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

            <Table striped bordered hover>
                <tbody>
                  
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiLightbulbOutline } size={1}  className="align-middle" title="luz"/> Luz</td>
                        <td className='text-left'>{(unCampoOculto.result.luz === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiPhoneClassic } size={1}  className="align-middle" title="telefono"/> Teléfono</td>
                        <td className='text-left'>{(unCampoOculto.result.telefono === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiWaterPump } size={1}  className="align-middle" title="agua corriente"/> Agua corriente</td>
                        <td className='text-left'>{(unCampoOculto.result.aguaCorriente === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                        <tr>
                            <td className='withTd'><Icon path={mdiAlphaIBoxOutline} title="imp. inmobiliario" size={1}  className="align-middle"/> Impuesto inmobiliario</td>

                            {(unCampoOculto.result.impuestoInmobiliario === '0')?(<td className='text-left'>${formatNumber(unCampoOculto.result.impuestoInmobiliario)}</td>):
                            ((unCampoOculto.result.impuestoInmobiliario === '1')?(<td className='text-left'>----</td>):
                            (<td className='text-left'>${formatNumber(unCampoOculto.result.impuestoInmobiliario)}</td>
                            ))
                            }
                        </tr>
                      }
                </tbody>
            </Table>
        </Col>

        <Col md={2} lg={2} xl={2} className="paddingMobile p-md-4 widthTable paddingId" >

            <Table striped bordered hover>
                <tbody>
                  {
                    (unCampoOculto.result.boolIndiceConeat === '1')?(
                      <>
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaIBoxOutline } size={1}  className="align-middle" title="indice coneat"/> Indice coneat</td>
                        <td className='text-left'>{unCampoOculto.result.indiceConeat}</td>
                    </tr>
                    </>
                    ):(null)
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaPBoxOutline } size={1}  className="align-middle" title="acepta permutas"/> Acepta permutas</td>
                        <td className='text-left'>{(unCampoOculto.result.aceptaPermutas === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                  {
                    <tr>
                        <td className='withTd'><Icon path={mdiAlphaFBoxOutline } size={1}  className="align-middle" title="acepta financiacion"/> Acepta financiación</td>
                        <td className='text-left'>{(unCampoOculto.result.aceptaFinanciacion === '1')?('Si'):('No')}</td>
                    </tr>
                  }
                </tbody>
            </Table>
        </Col>

    </Row>
    </>
  ):(null)}

  <MapPropiedad longitud={unCampoOculto.result.longitud} latitud={unCampoOculto.result.latitud}/>

  <ModalFotos
        show={modalShow}
        onHide={() => setModalShow(false)}
        className='modal fondoModal'
        src={unCampoOculto.result.image} precio={unCampoOculto.result.precio} id={props.id} tipo='c'
        modalShow={modalShow} setModalShow={setModalShow}
  />

  <Footer/>

</Row>
  )
}

export default CampoOculto