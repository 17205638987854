//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Badge, Carousel } from 'react-bootstrap'
import '../css/card.css'
import {urlApi} from '../services/apirest'

const SectorImages = (props) => {

  let arrayImages = []
  let carousel = []

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num)
  }

  if(props.images !== undefined){
    carousel = []
    arrayImages = []
    arrayImages = props.images.split('[[END]]')
       
    arrayImages.map((item,idx)=>{
      carousel.push(<Carousel.Item key={idx} className='item'><Badge bg="colorBadge" >U$S {formatNumber(props.precio)}</Badge><img loading='lazy' className="d-block w-100 tamano" src={urlApi+item}/></Carousel.Item>)
    })
    
  }
  


  

  return (
    <Carousel className='zoom' controls={false} interval={null}>
      {carousel}
    </Carousel>
  )
}

export default SectorImages