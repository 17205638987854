//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE

import React from 'react'
import '../css/propiedades.css'
import Icon from '@mdi/react'
import { mdiHomeSearch } from '@mdi/js';
import { Col, Row } from 'react-bootstrap'

const NotFound = () => {
  return (
    <>
    <Row className="justify-content-center p-0 m-0">
        <Col xs={12} md={67} lg={8} className="p-3 align-item-center notFound">
            <Icon path={mdiHomeSearch} size={10}  className="align-middle"/>
            <h2>No hay propiedades disponibles con esas características</h2>
            <h5>Contáctese con nosotros, por mas propiedades disponibles.</h5>
        </Col> 
    </Row>
    </>
  )
}

export default NotFound
