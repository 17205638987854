//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE

import React from 'react'
import { Badge, Carousel,Button } from 'react-bootstrap'
import '../../css/carouselPropiedades.css'
import {urlApi} from '../../services/apirest'
import Icon from '@mdi/react'
import  {mdiMagnifyMinus} from '@mdi/js';

const CarouselPropiedad4 = (props) => {

    let arrayImagesUnaPropiedad = []
    let carouselUnaPropiedad = []

    const formatNumber = (num) => {
      return new Intl.NumberFormat().format(num)
    }

    if(props.src !== undefined){
      carouselUnaPropiedad = []
      arrayImagesUnaPropiedad = []
      arrayImagesUnaPropiedad = props.src.split('[[END]]')

      arrayImagesUnaPropiedad.map((item,idx)=>{
          carouselUnaPropiedad.push(<Carousel.Item key={idx} className='item'><Badge bg="colorBadge" className='propiedadCarousel2'>U$S {formatNumber(props.precio)}</Badge><img loading='lazy' id='imgs' style={{'max-height': '100vh','max-width':'100vw'}} src={urlApi+item}/><Badge bg="colorBadge" className='propiedadCarouselRef2'>Ref. w{props.tipo}{props.id}4</Badge><Button onClick={() => zoom()} className='propiedadesCarouselZoom'><Icon color={'#23395B'} path={mdiMagnifyMinus } size={1.2} style={{'padding':'0'}} className="align-middle"/></Button></Carousel.Item>)
      })


      const zoom = () => {
          props.setModalShow(false)
      }
    }


  return (
    <>
        <Carousel interval={null}  slide={false}>
            {carouselUnaPropiedad}
        </Carousel>
    </>
  )
}

export default CarouselPropiedad4