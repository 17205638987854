//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import { Col,Row,Stack, Card } from 'react-bootstrap';
import { mdiWhatsapp,mdiCellphoneBasic,mdiEmailOutline } from '@mdi/js';
import Icon from '@mdi/react'
import '../../css/portada.css'
import Contacto2 from './Contacto2';
import '../../css/contacto2.css'



const Contactos = () => {


  return (
    <Row className="banner2 justify-content-center">
        <Col xs={12} /* sm={6} */ md={11} lg={9} align="center">
     
        <h1 style={{ 'color': 'white'}} className="p-4">Te podes comunicar con nosotros via:</h1>
  
        <Row className="justify-content-center">



        <Col xs={12} sm={5} md={6} lg={3} align="center" className='colComunicacion'>

              <Stack direction="vertical" gap={3}>

              <a href='https://api.whatsapp.com/send?phone=598099933330' target="_blank" style={{ 'color': '#00202A','textDecoration': 'none'}}>
                <Card className='cardContacto1-body' >
                  <Card.Body>
                    <Icon path={mdiWhatsapp} size={2} className="align-middle colorIconos p-0.8"/>
                    <h3>Whatsapp </h3>
                    <a href='https://api.whatsapp.com/send?phone=099933330' target="_blank" style={{ 'color': '#00202A'}}><h5 style={{ 'color': '#00202A'}}>099 933 330 </h5></a>
                  </Card.Body>
                </Card>
              </a>

              <a href="tel:099933330" style={{ 'color': '#00202A','textDecoration': 'none'}}>
                <Card className='cardContacto2-body'>
                  <Card.Body>
                   <Icon path={mdiCellphoneBasic } size={2} className="align-middle colorIconos p-0.8"/>
                   <h3 >Llamada </h3>
                   <a href="tel:099933330" style={{ 'color': '#00202A'}}><h5 style={{ 'color': '#00202A'}}>099 933 330 </h5></a>
                  </Card.Body>
                </Card>
              </a>

              <a href="mailto:info@will.com.uy?Subject=Consulta%20desde%20web" style={{ 'color': '#00202A','textDecoration': 'none'}}>
                <Card className='cardContacto3-body'>
                  <Card.Body>
                    <Icon path={mdiEmailOutline } size={2} className="align-middle colorIconos p-0.8"/>
                    <h3>E-mail </h3>
                    <a href="mailto:info@will.com.uy?Subject=Consulta%20desde%20web" style={{ 'color': '#00202A'}}><h5 style={{ 'color': '#00202A'}}>info@will.com.uy </h5> </a>
                  </Card.Body>
                </Card>
                </a>

                </Stack>
        </Col>

        <Col xs={12} sm={5} md={8} lg={6} className='colContacto pb-lg-4'>
          <Contacto2/>
        </Col>

        </Row>
        </Col>

    </Row>
  )
}

export default Contactos