//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE

import React from 'react'
import { Form,Col,Row,Button,Stack,ButtonGroup,ToggleButton } from 'react-bootstrap';
import '../css/filter.css'
import Icon from '@mdi/react'
import { mdiChevronDown,mdiChevronUp,mdiArrowDownThin,mdiArrowUpThin } from '@mdi/js';


const Filter = ({propiedadSelect,setPropiedadSelect,setDatos}) => {


  const [flagFilter, setFlagFilter] = React.useState(true)

  const [ubicacion, setUbicacion] = React.useState('SD')
  const [dormitorio, setDormitorio] = React.useState('SD')
  const [bano, setBano] = React.useState('SD')
  const [precio, setPrecio] = React.useState('SD')
  
  const [garage, setGarage] = React.useState(false)

  const [metros,setMetros] = React.useState('SD')
  

  const [hectarias,setHectarias] = React.useState('SD')
  const [orderBy, setOrderBy] = React.useState('reciente');



  const mostrarFilter = () => {
    document.getElementById('filter').style.display = 'block';
    document.getElementById('margenPaddingFilter').style.paddingBottom = '4%';
    document.getElementById('margenPaddingFilter').style.marginTop = '5%';
    document.getElementById('margenPaddingFilter').style.marginLeft = '5%';
    document.getElementById('margenPaddingFilter').style.marginRight = '5%';
    document.getElementById('margenPaddingFilter').style.marginBottom = '0';
    setFlagFilter(false)
  }

  const ocultarFilter = () => {
    document.getElementById('filter').style.display = 'none';
    document.getElementById('margenPaddingFilter').style.paddingBottom = 0;
    setFlagFilter(true)
  }

  var anchoVentana = window.innerWidth

  window.onresize = function(){
    anchoVentana = window.innerWidth;
   };


    React.useEffect(() => {
      setDatos(null)
    
      let datosNuevos = {
        tipo : propiedadSelect,
        ubicacion : ubicacion,
        dormitorio : dormitorio,
        bano : bano,
        precio: precio,
        garage : garage,
        metros : metros,
        hectarias : hectarias,
        orderBy: orderBy,
      }

      setDatos(datosNuevos)
      datosNuevos = null

  }, [propiedadSelect,ubicacion,dormitorio,bano,garage,precio,hectarias,metros,orderBy])


  React.useEffect(() => {
    localStorage.setItem('type',`${propiedadSelect}`)
  }, [propiedadSelect])
  

  let auxMenor = []
  let auxMayor = []

  auxMenor.push(<><Icon path={mdiArrowDownThin } title="precio ascendente" size={1}  className="align-middle"/>Precio</>)
  auxMayor.push(<><Icon path={mdiArrowUpThin } title="precio descendente" size={1}  className="align-middle"/>Precio</>)
  const radios = [
    { name: 'Recientes', value: 'reciente' },
    { name: auxMenor, value: 'menor' },
    { name: auxMayor, value: 'mayor' },
  ];

  return (
      <>
        
        <Col xs={11} sm={7} md={12} lg={9} xl={9} xxl={9} className="ColGral" id="margenPaddingFilter"> 
          
          <div  className="ms-auto d-block d-md-none buttonMargin">
          <Stack direction="horizontal" gap={3}>
            <div className='filter' style={{'padding':'0','margin':'0','height': '40px'}}><h6 style={{'lineHeight': '40px'}}>Filtros</h6></div>
            {(flagFilter)?(<Button size="sm p-0 m-0" className="ms-auto d-block d-md-none" onClick={mostrarFilter}><Icon path={mdiChevronDown} size={1}/></Button>):(<Button size="sm p-0 m-0" className="ms-auto d-block d-md-none" onClick={ocultarFilter}><Icon path={mdiChevronUp} size={1}/></Button>)} 
          </Stack>
          </div>

         
          <div id='filter' className='pt-3 pb-3'>
          <Form>

          <Row className="g-0 justify-content-center">

            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}> 
                <ButtonGroup>
                {radios.map((radio,idx) => (
                  <ToggleButton
                    key = {idx}
                    id={`radio-${idx}`}
                    type="radio"
                    name="radio"
                    value={radio.value}
                    checked={orderBy === radio.value}
                    onChange={(e) => setOrderBy(e.currentTarget.value)}
                    size="sm"
                    style={{'padding-right':2,'padding-left':2}}
                  >
                    {radio.name}
                  </ToggleButton>
                  ))}
                </ButtonGroup>
            </Col>
          </Row>

          <hr></hr>
          <Col>
            <span className='options'>
              Tipo
            </span>
          </Col>
          <Col xs={11} md={11} lg={11} className='select-box'>       
            <Form.Select className='form-select' value={propiedadSelect} onChange={e=>{setPropiedadSelect(e.target.value)}}>
              <option  value="C">Casa</option>
              <option  value="A">Apartamento</option>
              <option  value="T">Terreno</option>
              <option  value="CA">Campos</option>
            </Form.Select>
          </Col>
          <hr></hr>
          <Col >
            <span className='options'>
              Ubicación
            </span>
          </Col>
          <Col xs={11} md={11} lg={11}>       
            <Form.Select value={ubicacion} onChange={e=>{setUbicacion(e.target.value)}}>
              <option value="SD">Seleccionar...</option>
              <option value="Maldonado">Maldonado</option>
              <option value="Punta del Este">Punta del Este</option>
              <option value="La Barra">La Barra</option>
              <option value="Manantiales">Manantiales</option>
              <option value="El Chorro">El Chorro</option>
              <option value="Bal. Buenos Aires">Bal. Buenos Aires</option>
              <option value="Jose Ignacio">Jose Ignacio</option>
              <option value="Garzon">Garzón</option>
              <option value="San Carlos">San Carlos</option>
              <option value="Piriapolis">Piriápolis</option>
              <option value="Otros">Otros</option>
            </Form.Select>
          </Col>

          
          {(propiedadSelect === 'A' || propiedadSelect === 'C')?
          (
            <>
            <hr></hr>
            <Col >
              <span className='options'>
                Dormitorios
              </span>
            </Col>
            <Col xs={11} md={11} lg={11}> 
            <Form.Select value={dormitorio} onChange={e=>{setDormitorio(e.target.value)}}>
                <option value="SD">Seleccionar...</option>
                <option value="0">Monoambiente</option>
                <option value="1">1 Dormitorio</option>
                <option value="1.5">1 ½ Dormitorio</option>
                <option value="2">2 Dormitorios</option>
                <option value="3">3 Dormitorios</option>
                <option value="4">4+ Dormitorios</option>
              </Form.Select>
            </Col>
          
          <hr></hr>
          <Col xs={12} md={12} lg={12}>
            <span className='options'>
              Baños
            </span>
          </Col>
          <Col xs={11} md={11} lg={11}>       

          <Form.Select value={bano} onChange={e=>{setBano(e.target.value)}}>
              <option value="SD">Seleccionar...</option>
              <option value="1">1 Baño</option>
              <option value="2">2 Baños</option>
              <option value="3">3 Baños</option>
              <option value="4">4+ Baños</option>
            </Form.Select>
          </Col>
            </>
            ):
            (
              (propiedadSelect === 'T')?(
              <>
              <hr></hr>
              <Col xs={12} md={12} lg={12}>
                <span className='options'>
                  Metros²
                </span>
              </Col>
              <Col xs={11} md={11} lg={11}>       

              <Form.Select value={metros} onChange={e=>{setMetros(e.target.value)}}>
                  <option value="SD">Seleccionar...</option>
                  <option value="0-500">0 - 500</option>
                  <option value="500-1000">500 - 1000</option>
                  <option value="1000-1500">1000 - 1500</option>
                  <option value="+1500">+1500</option>
                </Form.Select>
              </Col>
              </>
            ):
            (
              (propiedadSelect === 'CA')?(
                <>
              <hr></hr>
              <Col xs={12} md={12} lg={12}>
                <span className='options'>
                  Hectareas
                </span>
              </Col>
              <Col xs={11} md={11} lg={11}>       

              <Form.Select value={hectarias} onChange={e=>{setHectarias(e.target.value)}}>
                  <option value="SD">Seleccionar...</option>
                  <option value="0-5">0 - 5</option>
                  <option value="5-30">5 - 30</option>
                  <option value="30-100">30 - 100</option>
                  <option value="+100">+100</option>
                </Form.Select>
              </Col>
              </>
            ):(null)
            

            )
          )}

          <hr></hr>
            <Col >
              <span className='options'>
                Precio (U$S)
              </span>
            </Col>
            {(propiedadSelect === 'T')?

            (<Col xs={11} md={11} lg={11}> 
            <Form.Select value={precio} onChange={e=>{setPrecio(e.target.value)}}>
                <option value="SD">Seleccionar...</option>
                <option value="0-30">0 - 30.000</option>
                <option value="30-60">30.000 - 60.000</option>
                <option value="60-120">60.000 - 120.000</option>
                <option value="+120">+ 120.000</option>
              </Form.Select>
            </Col>):

            (<Col xs={11} md={11} lg={11}> 
            <Form.Select value={precio} onChange={e=>{setPrecio(e.target.value)}}>
                <option value="SD">Seleccionar...</option>
                <option value="0-100">0 - 100.000</option>
                <option value="100-300">100.000 - 300.000</option>
                <option value="300-500">300.000 - 500.000</option>
                <option value="+500">+ 500.000</option>
              </Form.Select>
            </Col>)}

          {(propiedadSelect === 'A' || propiedadSelect === 'C')?
          (
          <>
          <hr></hr>
          <Col >
            <span className='options'>
              Garage
            </span>
          </Col>

          <Col> 

            <Form.Check 
            type="switch"
             id="custom-switch"
             label="Con garage"
             checked={garage}
             onChange={()=>setGarage(!garage)}
             
            />

          </Col>
          </>
          ):
            (null)
          }

          <hr></hr>

          </Form>
        </div>

        </Col>
        </>       

  )
}

export default Filter