import axios from 'axios'
import {urlApi} from '../../../services/apirest'

//constantes
const dataInicial = {
    status : "",
    result : [],
    conectado : false,
    isFetching : false,
}


//Types
const FILTRO_UN_TERRENO_OCULTO_EXITO = 'FILTRO_UN_TERRENO_OCULTO_EXITO'
const FILTRO_UN_TERRENO_OCULTO_ERROR = 'FILTRO_UN_TERRENO_OCULTO_ERROR'
const FILTRO_UN_TERRENO_OCULTO_ESPERANDO = 'FILTRO_UN_TERRENO_OCULTO_ESPERANDO'

//reducer
export default function filtroUnTerrenoOcultoReducer(state = dataInicial, action){
    switch(action.type){

        case FILTRO_UN_TERRENO_OCULTO_ESPERANDO:
            return {
                ...state,
                isFetching: true,
                conectado : false
            }

        case FILTRO_UN_TERRENO_OCULTO_EXITO:
            return {...state, 
                isFetching: false, 
                status: 'ok',
                result: action.payload,
                conectado : true
            }

        case FILTRO_UN_TERRENO_OCULTO_ERROR:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                result: action.payload.result
            }

        default:
            return state
    }
}

//acciones
export const filtroUnTerrenoOculto = (terrenoId) => async (dispatch) => {

    const url = urlApi + 'obtenerTerrenoInactivo'
    dispatch({
        type: FILTRO_UN_TERRENO_OCULTO_ESPERANDO,
    })

    try {
        let datos={
            token: localStorage.getItem('terrenoInactivo').toString().substring(1,33), 
            terrenoId: terrenoId
        }
        //const res = await axios.get(url+'?inactivoTerrenoId='+terrenoId)
        const res = await axios.post(url,datos)
        
        dispatch({
            type: FILTRO_UN_TERRENO_OCULTO_EXITO,
            payload: res.data[0]
        })

    }catch (error) {

        dispatch({
            type: FILTRO_UN_TERRENO_OCULTO_ERROR,
            payload: {  status: 'error',
                        result: {error_id: 0,error_msg: ''+error}
                    }
        })
    }
 
} 

