//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE


import React from 'react'
import '../css/portada.css'
import '../css/filter.css'
import Typed from 'typed.js';
import { Button,Col,Row,Stack } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiCellphoneBasic,mdiEmailOutline,mdiFacebook,mdiInstagram  } from '@mdi/js';
import logo from '../img/Logo_transparente.png'
import Footer from './Footer';


const Portada = () => {
   
/* window.onload = function () {
var typed = new Typed('.typed', {

	stringsElement: '#cadenas-texto', // ID del elemento que contiene cadenas de texto a mostrar.
	typeSpeed: 80, // Velocidad en mlisegundos para poner una letra,
	startDelay: 160, // Tiempo de retraso en iniciar la animacion. Aplica tambien cuando termina y vuelve a iniciar,
	backSpeed: 75, // Velocidad en milisegundos para borrrar una letra,
	smartBackspace: true, // Eliminar solamente las palabras que sean nuevas en una cadena de texto.
	shuffle: false, // Alterar el orden en el que escribe las palabras.
	backDelay: 1500, // Tiempo de espera despues de que termina de escribir una palabra.
	//loop: true, // Repetir el array de strings
	loopCount: false, // Cantidad de veces a repetir el array.  false = infinite
	showCursor: true, // Mostrar cursor palpitanto
	cursorChar: '|', // Caracter para el cursor
	contentType: 'html', // 'html' o 'null' para texto sin formato
});
} */

React.useEffect(() => {
    var typed = new Typed('.typed', {

        stringsElement: '#cadenas-texto', // ID del elemento que contiene cadenas de texto a mostrar.
        typeSpeed: 80, // Velocidad en mlisegundos para poner una letra,
        startDelay: 160, // Tiempo de retraso en iniciar la animacion. Aplica tambien cuando termina y vuelve a iniciar,
        backSpeed: 75, // Velocidad en milisegundos para borrrar una letra,
        smartBackspace: true, // Eliminar solamente las palabras que sean nuevas en una cadena de texto.
        shuffle: false, // Alterar el orden en el que escribe las palabras.
        backDelay: 1500, // Tiempo de espera despues de que termina de escribir una palabra.
        //loop: true, // Repetir el array de strings
        loopCount: false, // Cantidad de veces a repetir el array.  false = infinite
        showCursor: true, // Mostrar cursor palpitanto
        cursorChar: '|', // Caracter para el cursor
        contentType: 'html', // 'html' o 'null' para texto sin formato
    });
}, [ ])




     
 
  return (

            <>
            <Row className="banner justify-content-center g-y-0 portadaG" style={{'padding-right': '0px !important','padding-left': '0px !important','margin-right': '0px','margin-left': '0px'}}>

                <Row className="justify-content-center">
                    <Col xs={12} md={10} lg={4} className='contLogo'>
                        <img
                            src={logo}
                            className="logo"
                        />
                    </Col>
                </Row>


                <Row className="justify-content-center pt-md-3 mt-md-3 paddingTexto">
                    <Col xs={12} md={6} lg={6} className="centrador contLogo">
                        <h2 className='titulo'>
                            <span className='typed'></span>
                        </h2>
                        <div className="titulo" id="cadenas-texto">
		                	<p>En Will te ayudamos a encontrar tu <i className="propiedad">Casa</i></p>
		                	<p>En Will te ayudamos a encontrar tu <i className="propiedad">Apartamento</i></p>
		                	<p>En Will te ayudamos a encontrar tu <i className="propiedad">Terreno</i></p>
                            <p>En Will te ayudamos a encontrar tu <i className="propiedad">Inversión</i></p>
                            <p>En Will te ayudamos a encontrar tu <i className="propiedad">Lugar...</i></p>
                            <p>En Will te ayudamos a encontrar tu <i className="propiedad">Lugar...</i></p>
		                </div> 
                    </Col>
                </Row>
             

                <Row md={6} lg={6} className="justify-content-center pt-0 mt-0 gap-0">

                    <Col md={9} lg={9} className="buttonW">
                        <Row className="justify-content-center">
                        <Col xs={12} md={2} lg={2} className='centrador d-grid gap-2 p-3'>
                            <Button size="md" className='button-portada' onClick={() => {localStorage.setItem('type','C')}} href="/propiedades">Casas</Button>
                        </Col> 


                        <Col xs={12} md={2} lg={2} className='centrador d-grid gap-2 p-3 buttonApt'>
                            <Button size="md" className='button-portada' onClick={() => {localStorage.setItem('type','A')}} href="/propiedades">Apartamentos</Button>
                        </Col>

                        <Col xs={12} md={2} lg={2} className='centrador d-grid gap-2 p-3'>
                            <Button size="md" className='button-portada' onClick={() => {localStorage.setItem('type','T')}} href="/propiedades">Terrenos</Button>
                        </Col>

                        <Col xs={12} md={2} lg={2} className='centrador d-grid gap-2 p-3'>
                            <Button size="md" className='button-portada' onClick={() => {localStorage.setItem('type','CA')}} href="/propiedades">Campos</Button>
                        </Col>
                        </Row>
                    </Col>
            
                
                </Row>


                  
            </Row>

            
            
            <Row className='footer-portada m-0 justify-content-center g-0 prueba '>{/* w-xs-100 */}

                <Col xs={12} md={8} lg={8}>
                    <Row className='nombrePortada g-0'> 
                        <Stack className='nombreFooter' direction="horizontal" gap={2}><div  className='WillFooter'>WILL</div><div className='NegociosFooter'>Negocios Inmobiliarios</div></Stack>
                    </Row>
                </Col>

        
                <Col xs={12} md={4} lg={4} >
                  <Row className='iconosFooter g-0'> 
                    
                      <Col xs={2} md={1} lg={1} className="p-0 email"><a href="mailto:info@will.com.uy?Subject=Consulta%20desde%20web" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiEmailOutline } size={1.1}/></a></Col>
                      <Col xs={2} md={1} lg={1} className="p-0 cel"><a href="tel:099933330" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiCellphoneBasic } size={1.1}/></a></Col>
                      <Col xs={2} md={1} lg={1} className="p-0 email"><a href="https://www.facebook.com/inmobiliariaWill" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiFacebook } size={1.1}/></a></Col>
                      <Col xs={2} md={1} lg={1} className="p-0 cel"><a href="https://www.instagram.com/inmobiliariaWill" style={{ 'color': '#fff','textDecoration': 'none'}}><Icon path={mdiInstagram } size={1.1}/></a></Col>
                    
                  </Row>
                </Col>
               
            </Row>


        </>
  
  )
}

export default Portada