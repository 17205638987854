import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Provider } from "react-redux"
import generateStore from "./redux/store";
import General from "./components/General"
import { Container } from 'react-bootstrap';
import Portada from "./components/Portada";
import Whatsapp from "./components/Whatsapp";
import PropiedadId from "./components/Propiedades/PropiedadId";
import TerrenoId from "./components/Propiedades/TerrenoId";
import CampoId from "./components/Propiedades/CampoId";
import PropiedadOculta from "./components/PropiedadOculta/PropiedadOculta";
import './css/app.css'
import './css/card.css'
import './css/carouselPropiedades.css'
import './css/contacto.css'
import './css/filter.css'
import './css/footer.css'
import './css/leaflet.css'
import './css/navBar.css'
import './css/portada.css'
import './css/propiedades.css'
import './css/variables.css'
import './css/whatsapp.css'
import './index.css'


function App() {

  const store = generateStore()

  return (

    
    <Provider store={store}>
    
    <Router>
    
      <Container fluid className='fondo containerPadding'>
      
      <Switch>

        <Route path="/" exact>
            <Portada className="p-0"/>
        </Route>

        <Route path="/propiedades" exact>
            <General/>
        </Route>

        <Route path="/propiedad/:id" exact>  
            <PropiedadId/>
        </Route>


        <Route path="/terreno/:id" exact>
            <TerrenoId/>
        </Route>

        <Route path="/campo/:id" exact>
            <CampoId/>
        </Route>

        <Route path="/contacto" exact>
            <General />
        </Route>

        {/* Ocu ind */}
        <Route path="/prop/:path/:id" exact>
            <PropiedadOculta />
        </Route>

        </Switch>
        <Whatsapp/>

       
      </Container>
      
    </Router>
    </Provider>

  )
}

export default App;
