//This software uses library MaterialDesign-React - see https://github.com/Templarian/MaterialDesign-React/blob/master/LICENSE
//This software uses library react-bootstrap - see https://github.com/react-bootstrap/react-bootstrap/blob/master/LICENSE
//This software uses library react-google-recaptcha - see https://github.com/dozoisch/react-google-recaptcha/blob/master/LICENSE

import React from 'react'
import { Button, Card, Col, FloatingLabel, Form, Row, Alert } from 'react-bootstrap'
import '../../css/contacto2.css'
import '../../css/contacto.css'
import '../../css/filter.css'



//Google ReCaptcha
import ReCAPTCHA from "react-google-recaptcha";
import ModalConfirm from '../ModalConfirm'

const Contacto2 = () => {

    // FUNCION RECAPTCHA
    const [responseCaptcha, setResponseCaptcha] = React.useState(false);

    async function onChangeReCaptcha(token) {

      var formDataCaptcha = new FormData();
      formDataCaptcha.append("token", token);

      const response = await fetch(
        "https://will.com.uy/back-inm/contacto/recaptcha/ReCaptcha.php",
        //"https://will.com.uy/comunicacion/recaptcha/ReCaptcha.php",
        //"http://localhost:8080/inm-back/contacto/recaptcha/ReCaptcha.php",
        {
          method: "POST",
          body: formDataCaptcha,
        }
      ).catch(function (error) {
        console.log("Hubo un problema con la petición Fetch:" + error.message);
      });
  
      const respuesta = await response.json();
    
        if (respuesta.success === true) {
          setResponseCaptcha(true) 
        } else {
          setResponseCaptcha(false)
        }
  
    }
  
    const [auxResponseCaptcha, setAuxResponseCaptcha] = React.useState(false);
    React.useEffect(() => {
      setAuxResponseCaptcha(responseCaptcha);
    }, [responseCaptcha])

    //Validacion de datos
    
    const [valid,setValid] = React.useState('true')
    const [validated, setValidated] = React.useState(false);
    const [auxConfirm, setAuxConfirm] = React.useState(false);



    const [nombre,setNombre] = React.useState('')
    const [email,setEmail] = React.useState('')
    const [celular,setCelular] = React.useState('')
    const [comentario,setComentario] = React.useState('')


    const validarDatos = (e) => {

      setAuxConfirm(true)
      
      
      e.preventDefault()

      const form = e.currentTarget;

      if (form.checkValidity() === true) {

        setValid('true')
        

        if (auxResponseCaptcha === true){
          let body = new FormData();
          body.append('nombre', nombre)
          body.append('email', email)
          body.append('celular', celular)
          body.append('comentario', comentario)

          sendMail(body);
        }else{
          console.log('captcha false')
        }

      }else{
        e.preventDefault();
        e.stopPropagation();
        setValid('false')
      }

      setValidated(true);
    }

    let variableMsgError = []
    if(valid === 'false'){
      variableMsgError.push(<Row className='justify-content-center pt-3'><Col md={10} lg={10}><Alert className="text-center"  variant='danger'>Por favor complete todos los campos de forma correcta</Alert></Col></Row>)}
    
    let auxMsg = []

    if(auxResponseCaptcha === false && auxConfirm === true){
      auxMsg.push(<Row className='justify-content-center'><Col md={7} lg={7}><Alert className="text-center"  variant='danger'>Por favor complete el reCAPTCHA</Alert></Col></Row>)
    }

   



  const [modalShow, setModalShow] = React.useState(false);
  const [response, setResponse] = React.useState("");

  //*****SEND MAIL******//
  //*******************//
  const sendMail = (body) => {
    fetch("https://will.com.uy/back-inm/contacto/sendMail/sendMail.php", {
      //"https://will.com.uy/comunicacion/sendMail/sendMail.php", {
    //fetch("http://localhost:8080/inm-back/contacto/sendMail/sendMail.php", {
      method: "POST",
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === "ERROR") {
          setResponse("ERROR");
          setModalShow(true);
        } else {
          setResponse("OK");
          setModalShow(true);
          setNombre('')
          setEmail('')
          setCelular('')
          setComentario('')
          setValid(true)
          setValidated(false)
          setAuxConfirm(false)
        }
      });
  };


  return (
      <>
    
    <Row>
        <Col xs={12} md={12} lg={12} xl={12}>

            <Card className='p-3 cardContacto-body m-0'> 

                <h2 style={{ 'color': '#00202A'}}>Escribinos tu mensaje:</h2>
              <Form noValidate validated={validated} onSubmit={validarDatos}>

                {/* Nombre */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nombre"
                  className="mb-3 "
                >
                    <Form.Control value={nombre} required type="text" pattern="(^[A-ZÁÉÍÓÚÑa-záéíóúñ]+$)|(^[A-ZÁÉÍÓÚÑa-záéíóúñ]+[ ]+[A-ZÁÉÍÓÚÑa-záéíóúñ]+$)|(^[A-ZÁÉÍÓÚÑa-záéíóúñ]+[ ]+[A-ZÁÉÍÓÚÑa-záéíóúñ]+[ ]+[A-ZÁÉÍÓÚÑa-záéíóúñ]+$)|(^[A-ZÁÉÍÓÚÑa-záéíóúñ]+[ ]+[A-ZÁÉÍÓÚÑa-záéíóúñ]+[ ]+[A-ZÁÉÍÓÚÑa-záéíóúñ]+[ ]+[A-ZÁÉÍÓÚÑa-záéíóúñ]+$)" onChange={ (e) => setNombre(e.target.value)}/>
                </FloatingLabel>


                {/* Email */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                    <Form.Control value={email} required type="text" pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}" onChange={ (e) => setEmail(e.target.value)}/>
                </FloatingLabel>


                {/* Celular */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Celular"
                  className="mb-3"
                >
                    <Form.Control value={celular} type="text" required pattern="[0-9]{9,11}" onChange={ (e) => setCelular(e.target.value)}/>
                </FloatingLabel>

                {/* Comentarios */}
                <FloatingLabel controlId="floatingTextarea2" label="Comentario">
                  <Form.Control
                    type="text"
                    as="textarea"
                    style={{ height: '100px' }}
                    className="mb-4 commentInput"
                    required
                    value={comentario}
                    onChange={ (e) => setComentario(e.target.value)}     
                  />
                </FloatingLabel>



                {/* Google ReCaptcha */}
                <Row className="justify-content-center p-2 ">
                  <Col xs={12} sm={5} md={12} lg={12} xl={12}>
                    
                    <ReCAPTCHA
                      sitekey="6LcnP7QgAAAAAM1j26Qkk8TOmUykSC9s5bCSS6ry"
                      onChange={onChangeReCaptcha}
                      hl="es"
                      className="g-recaptcha"
                    />
                  </Col>
                </Row>

                <Row>
                    <Col className="d-grid gap-2">
                        <Button style={{ 'backgroundColor': '#8c3839', 'border': 'white'}} type="submit">Enviar</Button>
                    </Col>
                </Row>
              </Form>

              {variableMsgError} 
              {auxMsg} 
            </Card>

        </Col>
    </Row>

    <ModalConfirm
        response={response}
        show={modalShow}
        onHide={() => setModalShow(false)}
    />
    
    </>
  )
}

export default Contacto2